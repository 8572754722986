import React, { Component } from "react";
import { css } from "glamor";
import validation from "utils/validation";

const rules = {
  emoji: css({
    position: "absolute",
    color: "#c80000",
    top: "50%",
    right: "0.75rem",
    fontFamily: "IRANSans, SF",
    cursor: "help",
    transform: "translateY(-50%)",
  }),
};

export default class EmojiReaction extends Component {
  state = {
    emoji: "😑",
  };

  static getDerivedStateFromProps(nextProps) {
    const { validElements } = validation(nextProps.value);
    if (validElements === 0) return { emoji: "😑" };
    if (validElements === 1) return { emoji: "😕" };
    if (validElements === 2) return { emoji: "😊" };
    if (validElements === 3) return { emoji: "😎" };
    if (validElements === 4) return { emoji: "💪🏻" };
    return null;
  }
  render() {
    return (
      <span className={rules.emoji} role="img" aria-label="img">
        {this.state.emoji}
      </span>
    );
  }
}
