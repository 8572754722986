import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest}>
      {rest.isAuthenticated ? (
        <Component {...rest} />
      ) : (
        <Redirect to={rest.redirect} />
      )}
    </Route>
  );
}

export default AuthenticatedRoute;
