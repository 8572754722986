import classnames from 'classnames';
import dayjs from 'dayjs';
import renderDigits from 'helpers/renderDigits';
import { useTranslation } from 'react-i18next';
import toPersianDigits from 'utils/toPersianDigits';

export const EMOJIS = {
  available: '✅',
  unavailable: '🔴',
  consumed: '⏳',
};

function Receipt({ receipt }) {
  const { t } = useTranslation();
  const getValuesBeforeRender = (item) => {
    let periodPrice;
    let periodIndicator;
    let days;

    switch (item.plan) {
      case 'bronze':
        periodPrice = item.price / 1;
        periodIndicator = '۱ ماهه';
        days = 30;
        break;

      case 'silver':
        periodPrice = item.price / 3;
        periodIndicator = '۳ ماهه';
        days = 90;

        break;

      case 'gold':
        periodPrice = item.price / 6;
        periodIndicator = '۶ ماهه';
        days = 180;

        break;

      case 'platinum':
        periodPrice = item.price / 12;
        periodIndicator = '۱ ساله';
        days = 365;

        break;

      default:
        break;
    }

    return {
      periodPrice,
      periodIndicator,
      days,
    };
  };
  return (
    <div
      className='relative rounded-2xl bg-neutral-300 bg-opacity-50 bg-blend-soft-light shadow-sm backdrop-blur'
      dir='rtl'>
      <div className='flex items-center justify-between gap-6 rounded-t-2xl bg-neutral-800 px-4 py-2 bg-blend-soft-light'>
        <div className='flex items-center gap-2'>
          <div className="flex items-center gap-2 font-['IRANYekanX'] text-base font-bold leading-normal text-neutral-200">
            <div className="text-center font-['IRANYekanX'] text-2xl font-bold leading-loose text-neutral-200">
              {EMOJIS[receipt.status]}
            </div>
            <div className="flex gap-1 font-['IRANYekanX'] text-base font-bold leading-normal text-neutral-200">
              {receipt.status === 'available' && (
                <span>{renderDigits(dayjs(receipt?.period?.endAt).diff(new Date(), 'days'))}</span>
              )}
              {receipt.status === 'consumed' && <span>{renderDigits(getValuesBeforeRender(receipt._item).days)}</span>}
              {receipt.status === 'available' && <span>روز دیگر مانده</span>}
              {receipt.status === 'consumed' && <span>روز اضافه شد</span>}
            </div>
          </div>
          {/* <div className='flex items-center rounded bg-amber-400 bg-opacity-10 px-2 py-0.5 bg-blend-soft-light'>
            <div className="font-['IRANYekanX'] text-sm font-medium leading-normal text-amber-400">روز دیگر</div>
          </div> */}
        </div>
        <div
          className={classnames('flex items-center justify-center rounded-full bg-opacity-30 px-3 py-1 text-center', {
            'bg-emerald-400': receipt.status === 'available',
            'bg-red-500': receipt.status === 'unavailable',
            'bg-yellow-600': receipt.status === 'consumed',
          })}>
          <div
            className={classnames("font-['IRANYekanX'] text-sm font-medium leading-normal", {
              'text-emerald-400': receipt.status === 'available',
              'text-red-500': receipt.status === 'unavailable',
              'text-yellow-600': receipt.status === 'consumed',
            })}>
            {receipt.status !== 'pending' && t(`common:${receipt.status}`)}
          </div>
        </div>
      </div>

      <div className='flex flex-col items-start gap-4 p-4'>
        <div className='flex items-baseline justify-between gap-1 self-stretch'>
          <div className="font-['IRANYekanX'] text-base font-normal leading-normal text-neutral-400">مقدار</div>
          <div className='flex items-baseline gap-1'>
            <div className="shrink grow basis-0 font-['IRANYekanX'] text-base font-medium leading-normal text-neutral-800">
              {toPersianDigits(receipt._invoice?.amount, { maximumFractionDigits: 3 })}
            </div>
            <div className="font-['IRANYekanX'] text-xs font-medium leading-none text-neutral-400">
              {receipt._invoice?.currency.symbol}
            </div>
          </div>
        </div>
        <div className='flex items-baseline justify-between gap-1 self-stretch'>
          <div className='flex items-baseline gap-1 '>
            <div className="font-['IRANYekanX'] text-base font-normal leading-normal text-neutral-400">پلن</div>
            {/* <div className='flex rounded bg-amber-300 bg-opacity-40 px-1 py-0.5 bg-blend-soft-light'>
              <div className="font-['IRANYekanX'] text-sm font-medium leading-normal text-amber-300">
                {receipt._item.plan}
              </div>
            </div> */}
          </div>
          <div className='flex items-baseline gap-1'>
            <div className="shrink grow basis-0 font-['IRANYekanX'] text-base font-medium leading-normal text-neutral-800">
              {toPersianDigits(getValuesBeforeRender(receipt._item).periodIndicator, { maximumFractionDigits: 3 })}
            </div>
            {/* <div className="text-neutral-400 font-['IRANYekanX'] text-xs font-medium leading-none">ماهه</div> */}
          </div>
        </div>
        <div className='flex items-baseline justify-between gap-1 self-stretch'>
          <div className="font-['IRANYekanX'] text-base font-normal leading-normal text-neutral-400">شروع پلن</div>
          <div className='flex items-baseline gap-1'>
            <div className="shrink grow basis-0 font-['IRANYekanX'] text-base font-medium leading-normal text-neutral-800">
              {receipt.period?.startAt
                ? toPersianDigits(
                    dayjs(receipt.period?.startAt).locale('fa').calendar('jalali').format('D MMMM YYYY ساعت HH:mm:ss'),
                  )
                : 'ندارد'}
            </div>
          </div>
        </div>
        <div className='flex items-baseline justify-between gap-1 self-stretch'>
          <div className="font-['IRANYekanX'] text-base font-normal leading-normal text-neutral-400">انتها پلن</div>
          <div className='flex items-baseline gap-1'>
            <div className="shrink grow basis-0 font-['IRANYekanX'] text-base font-medium leading-normal text-neutral-800">
              {receipt.period?.endAt
                ? toPersianDigits(
                    dayjs(receipt.period?.endAt).locale('fa').calendar('jalali').format('D MMMM YYYY ساعت HH:mm:ss'),
                  )
                : 'ندارد'}
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-1 self-stretch'>
          <div className="shrink grow basis-0 font-['IRANYekanX'] text-sm font-medium leading-normal text-neutral-400">
            {toPersianDigits(
              dayjs(receipt.createdAt).locale('fa').calendar('jalali').format('D MMMM YYYY ساعت HH:mm:ss'),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
