export default {
  notEnoughBalance: 'موجودی کیف پول شما کافی نیست',
  increaseYourBalance: `موجودی فعلی شما {{balance}} اناریم میباشد، لطفا ابتدا کیف پول خود را شارژ کرده سپس اقدام کنید`,
  chargeBalance: 'شارژ کیف پول',
  inviteFriends: 'دعوت دوستان',
  registerNewUdid: 'گزینه دلخواه را انتخاب کنید',
  registering: 'در حال ثبت...',
  price: 'قیمت',
  lockedStatus: 'این فاکتور قبلا مصرف شده است',
  badRequestStatus: 'فاکتور معتبر نیست',
  misdirectedRequestStatus:
    'مشکلی در ارتباط با سرور‌های واسط اپل رخ داده لطفا مجددا تلاش کنید',
  methodNotAllowed: 'درخواست قابل ثبت نمیباشد لطفا اشتراک دیگری را انتخاب کنید',
};
