import React from 'react';
import classnames from 'classnames';

function Container({ children, className, px = true }) {
  return (
    <div
      className={classnames('mx-auto max-w-xl', {
        [className]: className,
        'px-5': px,
      })}>
      {children}
    </div>
  );
}

export default Container;
