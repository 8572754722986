import { SET_CURRENT_USER_AGENT, SET_NAVIGATOR_METADATA } from './actions';

const initialState = {
  userAgent: '',
  metadata: {
    safari: false,
    ios: false,
    standalone: false,
    instagram: false,
  },
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER_AGENT: {
      return {
        ...state,
        userAgent: action.userAgent,
      };
    }
    case SET_NAVIGATOR_METADATA: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          safari:
            action.navigator.vendor &&
            action.navigator.vendor.indexOf('Apple') > -1 &&
            action.navigator.userAgent &&
            action.navigator.userAgent.indexOf('CriOS') == -1 &&
            action.navigator.userAgent.indexOf('FxiOS') == -1,
          ios:
            /iphone|ipod|ipad/i.test(action.navigator.userAgent) ||
            (action.navigator.platform === 'MacIntel' &&
              action.navigator.maxTouchPoints > 1) ||
            false,
          standalone: action.navigator.standalone || false,
          instagram: action.navigator.userAgent.indexOf('Instagram') > -1,
        },
      };
    }
    default:
      return state;
  }
}
