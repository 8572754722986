export default {
  notEnoughBalance: 'Your wallet stock is not enough',
  increaseYourBalance: `Your current account balance is {{balance}}, please charge your wallet first and then take action`,
  chargeBalance: 'Charge balance',
  inviteFriends: 'Invite friends',
  registerNewUdid: 'Select prefered option',
  registering: 'Registering...',
  price: 'Price',
  lockedStatus: 'This receipt has been consumed before',
  badRequestStatus: 'The receipt is not valid',
  misdirectedRequestStatus:
    'A problem with connecting to apple server, please try again',
  methodNotAllowed:
    'Process can not be done, please select another subscription',
};
