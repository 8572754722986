import Error from 'components/Error';
import dayjs from 'dayjs';
import ApiError from 'helpers/ApiError';
import renderDigits from 'helpers/renderDigits';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getListResources } from 'services/api';
import { logoutUser } from 'services/auth/actions';
import to from 'utils/to';
import toPersianDigits from 'utils/toPersianDigits';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import Receipt from 'components/Receipt';
import Container from 'components/Container';

function Profile() {
  const [receipts, setReceipts] = React.useState(null);
  const [invoices, setInvoices] = React.useState(null);
  const [sessions, setSessions] = React.useState(null);
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);
  const auth = useSelector((state) => state.auth);
  const [isPremium, setIsPremium] = React.useState(false);
  const [activeReceipt, setActiveReceipt] = React.useState(false);
  const { t } = useTranslation();

  const loadAsyncData = async () => {
    setError(null);

    let err;
    let res;
    [err, res] = await to(
      getListResources({
        resource: 'receipts',
        queries: {
          _user: auth.user._id,
          population: '_item _invoice',
        },
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setReceipts(res.data.results);

    [err, res] = await to(
      getListResources({
        resource: 'sessions',
        queries: {
          _user: auth.user._id,
          status: 'active',
        },
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setSessions(res.data.results);

    [err, res] = await to(
      getListResources({
        resource: 'invoices',
        queries: {
          _user: auth.user._id,
        },
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setInvoices(res.data.results);
  };

  React.useEffect(() => {
    loadAsyncData();
  }, []);

  React.useEffect(() => {
    if (receipts?.length > 0) {
      const activeReceipt = receipts.find((receipt) => receipt.status === 'available');
      if (activeReceipt) {
        setIsPremium(true);
        setActiveReceipt(activeReceipt);
      }
    }
  }, [receipts]);

  if (error) return <Error centered retry={loadAsyncData} />;

  console.log({ receipts, auth, sessions });
  return (
    <section className='py-16 lg:py-20'>
      <Container>
        <div className='mb-4'>
          <div className='flex h-full w-full flex-col items-center gap-3 rounded-2xl bg-gray-100 bg-opacity-90 p-4 bg-blend-soft-light backdrop-blur-3xl'>
            <div className='relative -mt-12 flex items-center justify-center rounded-full border-4 border-gray-100 border-opacity-90'>
              {isPremium && (
                <span className='absolute -right-3 -top-3 rotate-45' style={{ fontSize: '35px', lineHeight: '1' }}>
                  👑
                </span>
              )}
              <img className='h-16 w-16 rounded-full' src='/apple-touch-icon.png' alt={auth.user.mobile.number} />
            </div>
            <div className='flex shrink grow basis-0 flex-col items-end justify-start gap-2'>
              <div className="self-stretch font-['Lalezar'] text-xl font-bold leading-7">{auth.user.mobile.number}</div>
            </div>
            <button
              className="font-['Lalezar'] font-bold text-red-500"
              onClick={() => {
                dispatch(logoutUser());
              }}>
              خروج
            </button>
          </div>
        </div>

        {/* {sessions.map((s) => (
        <div className='flex items-center gap-3 rounded-2xl bg-amber-200 p-4 bg-blend-soft-light backdrop-blur-3xl'>
          <div className='flex h-16 w-16 items-center justify-center'>
            <img className='border-neutral-700 h-16 w-16 rounded-full border' src={'/img/circle.png'} />
          </div>
          <div className='flex shrink grow basis-0 flex-col items-end justify-start gap-2'>
            <div className="text-neutral-900 self-stretch font-['IRANYekanX'] text-xl font-bold leading-7">
              {s.device?.family} {s.device?.os}
            </div>
            <div className="text-neutral-900 self-stretch font-['IRANYekanX'] text-base font-normal leading-normal">
              {s.status}
            </div>
          </div>
        </div>
      ))} */}

        {receipts && (
          <div
            className='fixed inset-x-0 bottom-0 z-10 flex items-center border-t border-white border-opacity-10 bg-gray-200 bg-opacity-70 text-center backdrop-blur-3xl'
            style={{
              paddingBottom: 'calc(env(safe-area-inset-bottom) + 1rem)',
            }}>
            <style>{`body { padding-bottom: 8rem }`}</style>
            <div className='w-full'>
              {isPremium && (
                <div class='h-2 w-full bg-gray-300'>
                  <div
                    class='h-2 bg-primary'
                    style={{
                      width:
                        (dayjs(activeReceipt?.period?.endAt).diff(new Date(), 'days') /
                          dayjs(activeReceipt?.period?.endAt).diff(activeReceipt?.period?.startAt, 'days')) *
                          100 +
                        '%',
                    }}
                  />
                </div>
              )}
              {isPremium && (
                <div className='px-5 pt-3'>
                  از اشتراک پریمیوم شما {renderDigits(dayjs(activeReceipt?.period?.endAt).diff(new Date(), 'days'))} روز
                  دیگر مانده است
                </div>
              )}
              {!isPremium && (
                <div className='flex flex-col gap-5 px-5 pt-3'>
                  <div>شما اشتراک پریمیوم فعالی ندارید</div>
                  <Link to='/sub' className='btn btn-success btn-block'>
                    خرید اشتراک
                  </Link>
                </div>
              )}

              {isPremium && (
                <div className='flex flex-col gap-5 px-5 pt-3'>
                  <Link to='/sub' className='btn btn-success btn-block'>
                    تمدید اشتراک
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>

      <Container>
        <div className='relative mt-10 border-t text-right'>
          <span
            style={{
              position: 'relative',
              top: '-1.2em',
              display: 'inline-block',
              padding: '0 0.35em',
              fontSize: '12px',
              fontWeight: 400,
              color: '#ababab',
              backgroundColor: '#fff',
            }}>
            خرید‌های شما
          </span>
        </div>
      </Container>

      <Container px={false}>
        <Swiper
          spaceBetween={15}
          slidesPerView={1.1}
          slidesOffsetBefore={15}
          slidesOffsetAfter={15}
          dir='rtl'
          direction='horizontal'>
          {receipts?.map((r, i) => (
            <SwiperSlide>
              <Receipt receipt={r} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      <Container>
        <div className='relative mt-10 border-t text-right'>
          <span
            style={{
              position: 'relative',
              top: '-1.2em',
              display: 'inline-block',
              padding: '0 0.35em',
              fontSize: '12px',
              fontWeight: 400,
              color: '#ababab',
              backgroundColor: '#fff',
            }}>
            فاکتورهای شما
          </span>
        </div>

        {invoices?.map((invoice, i) => (
          <div className='flex flex-col'>
            <div className='flex justify-between py-2'>
              <h1>
                {renderDigits(invoice.amount)} {invoice?.currency?.symbol} | {t(`common:${invoice.status}`)}
              </h1>
              <h1>{toPersianDigits(dayjs(invoice.createdAt).fromNow())}</h1>
            </div>
            {i !== invoices.length - 1 && <div className='border-b' />}
          </div>
        ))}
      </Container>
    </section>
  );
}

export default Profile;
