import axios from 'axios';
import qs from 'qs';

export const getListResources = ({ resource, queries }) => {
  const stringifiedQs = qs.stringify(queries);
  if (queries?.text) return axios.get(`${process.env.REACT_APP_API_URL}/${resource}/search/?${stringifiedQs}`);
  return axios.get(`${process.env.REACT_APP_API_URL}/${resource}/?${stringifiedQs}`);
};

export const getLookupResource = ({ resource, queries }) => {
  const stringifiedQs = qs.stringify(queries);
  return axios.get(`${process.env.REACT_APP_API_URL}/${resource}/lookup/?${stringifiedQs}`);
};

export const postResource = ({ resource, id, data }) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/${resource}/${id}`, data);
};

export const putResource = ({ resource, id, data }) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/${resource}/${id}`, data);
};

export const patchResource = ({ resource, id, data }) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}/${resource}/${id}`, data);
};

export const deleteResource = ({ resource, id }) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/${resource}/${id}`);
};
