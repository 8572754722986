import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { TextField } from "components/Fields";
import { css } from "glamor";

const rules = {
  dividerTop: css({
    "@media (max-width: 575.9px)": {
      marginTop: "-9px",
      borderTop: "2px solid #ccc",
      paddingTop: "15px",
    },
  }),
};

const lower = (value) => value && value.toLowerCase();

function LoginForm({ submitting, pristine, handlePasswordFocus, formValues }) {
  const { t } = useTranslation();
  return (
    <div {...rules.content} className="md:mt-5">
      <h2 className={`mb-5 ${rules.dividerTop}`}>{t("auth.login.title")}</h2>
      <div className="form-row">
        <Field
          name="identifier"
          type="tell"
          normalize={(value) => {
            if (!value) return value;

            const onlyNumbers = value.replace(/[^\d]/g, "");
            if (onlyNumbers[0] === "0") return onlyNumbers.slice(1);
            if (onlyNumbers[0] !== "9") return onlyNumbers.slice(1);
            else return onlyNumbers.slice(0, 10);
          }}
          containerClass="mb-3"
          className="input input-bordered w-full"
          ltr
          placeholder={t("glossary:placeholders.identifier")}
          component={TextField}
          autoFocus
          disabled={submitting}
        />
        <Field
          name="password"
          type="password"
          containerClass="mb-9"
          className="input input-bordered w-full"
          ltr
          placeholder={t("glossary:placeholders.password")}
          id="password"
          onFocus={handlePasswordFocus}
          onBlur={handlePasswordFocus}
          component={TextField}
          disabled={submitting}
        />
      </div>

      <button
        type="submit"
        name="submit"
        className={classnames("btn btn-primary btn-block mb-4", {
          "text-white progress": submitting,
        })}
        disabled={submitting || pristine}
      >
        {t("auth.login.login")}
      </button>
    </div>
  );
}

const mapStateToProps = ({ form: { login: { values = {} } = {} } }) => ({
  formValues: values,
});
export default connect(mapStateToProps)(LoginForm);
