import React, { Component } from "react";
import classnames from "classnames";
import { css } from "glamor";

const rules = {
  header: css({
    borderRadius: "5px",
    marginTop: "-130px",
  }),
};

export default class LoginOwn extends Component {
  render() {
    const { isPassword } = this.props;
    return (
      <div className={`d-sm-block ${rules.header}`}>
        <div className="owl">
          <div
            className={classnames("hand", {
              password: isPassword,
            })}
          />
          <div
            className={classnames("hand hand-r", {
              password: isPassword,
            })}
          />
          <div className="arms">
            <div
              className={classnames("arm", {
                password: isPassword,
              })}
            />
            <div
              className={classnames("arm arm-r", {
                password: isPassword,
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}
