import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { css } from "glamor";
import Login from "./screens/Login";
import Register from "./screens/Register";
// import Forgot from './screens/Forgot';

const rules = {
  container: css({
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0",
  }),
};

function Auth() {
  const match = useRouteMatch();

  return (
    <section className={rules.container}>
      <Switch>
        <Route path={`${match.url}/login`} component={Login} />
        <Route path={`${match.url}/register`} component={Register} />
        {/* <Route path={`${match.url}/forgot`} component={Forgot} /> */}
      </Switch>
    </section>
  );
}

export default Auth;
