import React from 'react';
import Error from './components/Error';
import Success from './components/Success';

function MessageCard(props) {
  if (props.success) return <Success {...props} />;
  return <Error {...props} />;
}

export default MessageCard;
