import isEmpty from 'lodash/isEmpty';
import { SET_CURRENT_USER } from './actions';

const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
};

export default function authReducer(state = initialState, action = {}) {
  const { type, user, error } = action;
  switch (type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        isAuthenticated: !isEmpty(user),
        user,
        error,
      };
    }

    default:
      return state;
  }
}
