import React from "react";
import qs from "qs";
import { Route, Redirect } from "react-router-dom";

const recursiveQueryStringRegExp = new RegExp(/(\?redirect=.+\?.+)\w+/);

function UnauthenticatedRoute({ component: Component, ...rest }) {
  const query = qs.parse(rest.location.search.substring(1));

  let redirect = query.redirect;
  if (recursiveQueryStringRegExp.test(rest.location.search))
    redirect = rest.location.search.substr("?redirect".length + 1);

  return (
    <Route {...rest}>
      {!rest.isAuthenticated ? (
        <Component {...rest} />
      ) : (
        <Redirect to={redirect || rest.redirect} />
      )}
    </Route>
  );
}

export default UnauthenticatedRoute;
