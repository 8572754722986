import * as React from 'react';

function SvgDanger(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 45.311 45.311' {...props}>
      <path d='M22.675.02l-.02.001-.02-.001C10.135.02 0 10.154 0 22.656c0 12.5 10.135 22.635 22.635 22.635h.04c12.5 0 22.635-10.135 22.635-22.635C45.311 10.154 35.176.02 22.675.02zm0 38.791l-.02-.001-.02.001a3.705 3.705 0 110-7.408h.04a3.705 3.705 0 110 7.408zm5.313-28.233c-.242 3.697-1.932 14.692-1.932 14.692 0 1.854-1.519 3.356-3.373 3.356h-.058c-1.853 0-3.372-1.504-3.372-3.356 0 0-1.689-10.995-1.931-14.692-.12-1.851 1.298-5.288 5.304-5.288l.029.001.029-.001c4.005 0 5.425 3.437 5.304 5.288z' />
    </svg>
  );
}

export default SvgDanger;
