import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth/reducer";
import agentReducer from "./agent/reducer";

export default (history) =>
  combineReducers({
    auth: authReducer,
    agent: agentReducer,
    form: reduxForm,
    router: connectRouter(history),
  });
