export default {
  back: 'عودة',
  close: 'إغلاق',
  more: 'اكثر',
  login: 'دخول',
  register: 'تسجيل',
  search: 'بحث',
  apps: 'التطبيقات',
  games: 'ألعاب',
  updates: 'التحديثات',
  today: 'اليوم',
  application: 'تطبيق',
  notFound: 'لم يتم العثور على',
  times: 'مرة',
  version: 'الإصدار',
  get: 'احصل على',
  last: 'الأخير',
  loading: 'جارٍ التحميل',
  account: 'الحساب',
  price: 'السعر',
  updating: 'تحديث',
  featured: 'مميزة',
  redeem: 'تخليص',
  agree: 'أوافق',
  haveQuestion: 'لديك سؤال',
  continue: 'متابعة',
  cancel: 'Cancel',
};
