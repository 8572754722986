import React, { Component } from "react";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { css } from "glamor";
import DangerIcon from "assets/svg/Danger";

const rules = {
  ltr: css({
    right: "0.75rem",
    left: "unset",
    color: "red",
  }),
  rtl: css({
    right: "unset",
    left: "0.75rem",
    color: "red",
  }),
  svg: css({
    position: "absolute",
    color: "#c80000",
    top: "50%",
    cursor: "help",
    transform: "translateY(-50%)",
  }),
};

function TextField({
  input,
  type,
  labelText,
  className,
  containerClass,
  placeholder,
  ltr,
  disabled,
  smallText,
  meta: { touched, error },
  extraChildren,
}) {
  const [alertIsVisible, setAlertIsVisible] = React.useState(true);

  const handleError = () => {
    if (!isEmpty(error)) {
      return (
        <DangerIcon
          className={classnames(`${rules.svg}`, {
            [`${rules.ltr}`]: ltr,
            [`${rules.rtl}`]: !ltr,
          })}
          fill="currentColor"
          fillRule="evenodd"
        />
      );
    }
  };

  const handleVisibility = () => {
    setAlertIsVisible(!alertIsVisible);
  };

  return (
    <div className={containerClass}>
      {labelText && <label htmlFor={input.name}>{labelText}</label>}
      <div
        className={classnames("relative", {
          "alert-validate": alertIsVisible && touched && error,
          ltr,
        })}
        dir={ltr ? "ltr" : "rtl"}
        data-validate={error}
      >
        <input
          {...input}
          type={type}
          placeholder={placeholder}
          className={classnames(className, {
            "input-error": touched && !isEmpty(error),
          })}
          onFocus={(e) => {
            input.onFocus(e);
            handleVisibility();
          }}
          onBlur={(e) => {
            input.onBlur(e);
            handleVisibility();
          }}
          disabled={disabled}
        />
        {touched && handleError()}
      </div>
      {smallText && <small className="text-muted">{smallText}</small>}
      {extraChildren && extraChildren()}
    </div>
  );
}

export default TextField;
