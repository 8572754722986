import * as React from 'react';

function SvgRefresh(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 400 399.999' {...props}>
      <path d='M361.796 209.654c-7.887 0-14.279 6.393-14.279 14.275 0 81.342-66.172 147.518-147.519 147.518-81.342 0-147.515-66.176-147.515-147.518 0-76.521 58.575-139.585 133.239-146.796v32.863c0 5.099 2.725 9.812 7.137 12.362a14.26 14.26 0 0014.275 0l82.904-47.857a14.262 14.262 0 007.137-12.361c0-5.1-2.717-9.815-7.137-12.363L207.134 1.913a14.27 14.27 0 00-14.275 0 14.284 14.284 0 00-7.137 12.363v34.212c-90.426 7.298-161.791 83.167-161.791 175.44 0 97.085 78.984 176.07 176.066 176.07 97.088 0 176.071-78.985 176.071-176.07 0-7.881-6.389-14.274-14.272-14.274z' />
    </svg>
  );
}

export default SvgRefresh;
