import 'dayjs/locale/ar';
import 'dayjs/locale/fa';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import createStore from 'services/store';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'components/Loading';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'i18n';
import { setCurrentUser, renewToken, logoutUser } from 'services/auth/actions';
import httpStatus from 'http-status';
import setAuthorizationToken from 'utils/setAuthorizationToken';

// Create a store and get back itself and its object
const { store } = createStore();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    const status = error?.response?.status;
    if (status === httpStatus.FORBIDDEN) store.dispatch(logoutUser());
    return Promise.reject(error);
  },
);
// check localStorage to grab token
if (localStorage.jwtToken && localStorage.jwtDecode !== 'undefined') {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(setCurrentUser({ ...jwtDecode(localStorage.jwtToken) }));
  store.dispatch(renewToken());
}

const I18nApp = () => {
  return (
    <React.Suspense fallback={<Loading pastDelay />}>
      <App />
    </React.Suspense>
  );
};

const root = document.querySelector('#root');
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nApp />
    </BrowserRouter>
  </Provider>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
