function MessageCardError(props) {
  return (
    <div className="message-card">
      <div className="error-box shadow-2xl mx-auto">
        <div className="dot" />
        <div className="dot two" />
        <div className="face2">
          <div className="eye" />
          <div className="eye right" />
          <div className="mouth sad" />
        </div>
        <div className="shadow move" />
        <div className="message">
          <h1 className="font-bold text-white px-1">{props.title}</h1>
          <h2 className="text-white px-1">{props.description}</h2>
        </div>
        <button
          className="text-danger button-box font-bold"
          onClick={props.handleToDo}
        >
          {props.toDo}
        </button>
      </div>
    </div>
  );
}

export default MessageCardError;
