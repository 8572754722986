export default {
  back: 'برگشت',
  close: 'بستن',
  more: 'بیشتر',
  login: 'ورود',
  register: 'ثبت‌نام',
  search: 'جستجو',
  apps: 'برنامه‌ها',
  games: 'بازی‌ها',
  updates: 'آپدیت‌ها',
  today: 'امروز',
  application: 'برنامه',
  notFound: 'نتیجه‌ای یافت نشد',
  times: 'بار',
  version: 'نسخه',
  get: 'دریافت',
  last: 'آخرین',
  loading: 'بارگذاری',
  account: 'اکانت',
  price: 'قیمت',
  updating: 'درحال به‌روز‌رسانی',
  featured: 'برگزیده',
  redeem: 'بازخرید',
  agree: 'فهمیدم',
  accept: 'باشه',
  haveQuestion: 'سوال دارم',
  continue: 'ادامه',
  myPurchased: 'خرید‌های من',
  favorites: 'مورد علاقه‌ها',
  security: 'امنیت',
  myPacks: 'پک‌های من',
  support: 'پشتیبانی',
  refresh: 'تازه‌سازی',
  exit: 'خروج',
  languages: 'زبان‌ها',
  toman: 'تومان',
  rial: 'ریال',
  currency: 'اناریم',
  pay: 'پرداخت',
  free: 'رایگان',
  themes: 'قالب ها',
  upgrade: 'ارتقا',
  cancel: 'انصراف',
  pending: 'در انتظار',
  failed: 'ناموفق',
  done: 'انجام شد',
  canceled: 'لغو شد',
  mismatch: 'عدم تطابق',
  consumed: 'مصرف شده',
  available: 'در دسترس',
  unavailable: 'ناموجود',
  paid: 'پرداخت شده',
  paid_over: 'پرداخت بیش از حد',
  wrong_amount: 'مبلغ نادرست',
  process: 'در حال پردازش',
  confirm_check: 'تایید چک',
  wrong_amount_waiting: 'مبلغ نادرست در انتظار',
  check: 'چک',
  fail: 'ناموفق',
  system_fail: 'خطای سیستمی',
  refund_process: 'در حال برگشت وجه',
  refund_fail: 'برگشت وجه ناموفق',
  refund_paid: 'برگشت وجه پرداخت شده',
  price: '{{price, toCurrency, renderDigits}} {{currency.symbol}}',
};
