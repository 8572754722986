export default {
  back: 'Back',
  close: 'Close',
  more: 'More',
  login: 'Login',
  register: 'Register',
  search: 'Search',
  apps: 'Apps',
  games: 'Games',
  updates: 'Updates',
  today: 'Today',
  application: 'Application',
  notFound: 'Not found',
  times: 'Times',
  version: 'version',
  get: 'Get',
  last: 'Last',
  loading: 'Loading',
  account: 'Account',
  price: 'Price',
  updating: 'Updating',
  featured: 'Featured',
  redeem: 'Redeem',
  agree: 'Agree',
  accept: 'Accept',
  haveQuestion: 'Have question',
  continue: 'Continue',
  myPurchased: 'My purchased',
  favorites: 'Favorites',
  security: 'Security',
  myPacks: 'My packs',
  support: 'Support',
  refresh: 'Refresh',
  exit: 'Exit',
  languages: 'Languages',
  toman: 'Toman',
  rial: 'Rial',
  currency: 'Anareum',
  pay: 'Pay',
  free: 'Free',
  themes: 'themes',
  upgrade: 'Upgrade',
  cancel: 'Cancel',
  pending: 'Pending',
  failed: 'Failed',
  done: 'Done',
  canceled: 'Canceled',
  mismatch: 'Mismatch',
  consumed: 'Consumed',
  available: 'Available',
  unavailable: 'Unavailable',
  paid: 'Paid',
  paid_over: 'Paid over',
  wrong_amount: 'Wrong amount',
  process: 'Process',
  confirm_check: 'Confirm check',
  wrong_amount_waiting: 'Wrong amount waiting',
  check: 'Check',
  fail: 'Fail',
  system_fail: 'System fail',
  refund_process: 'Refund process',
  refund_fail: 'Refund fail',
  refund_paid: 'Refund paid',
};
