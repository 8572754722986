export default {
  storeName: 'الاناردونی',
  facebook: 'Facebook',
  telegram: 'Telegram',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  copyLink: 'نسخ الرابط',
  copyLinkWithInvitationCode: 'Copy link with Invitation code',
  copied: 'Copied',
  reportBugInApplication: 'Report bug in application',
  balanceCurrency: 'ANA',
  addBalance: 'Charge',
  makeMoney: 'Make money',
  signCustomApplication: 'Sign Custom Application',
  mb: 'MB',
  gb: 'GB',
  pleaseChooseYourLanguage: 'الرجاء اختيار لغتك',
  toManageLanguageGoToProfile:
    'لإدارة إعدادات اللغة ، انتقل إلى قسم الملف الشخصي',
  english: 'English',
  persian: 'فارسی',
  arabian: 'عربي',
  placeholders: {
    identifier: 'رقم الهاتف أو البريد الإلكتروني',
    password: 'كلمة المرور',
  },
  validation: {
    empty: 'يجب ألا يكون هذا الحقل فارغًا',
    wrongEmail: 'البريد الإلكتروني غير صالح',
    wrongMobile: 'الجوال غير صالح',
  },
};
