export default {
  storeName: 'اناردونی',
  facebook: 'فیسبوک',
  telegram: 'تلگرام',
  instagram: 'اینستاگرام',
  linkedin: 'لینکداین',
  twitter: 'توییتر',
  copyLink: 'کپی کردن لینک',
  copyLinkWithInvitationCode: 'کپی کردن لینک با کد دعوت',
  copied: 'کپی شد',
  reportBugInApplication: 'گزارش مشکل در برنامه',
  balanceCurrency: 'اناریم',
  addBalance: 'افزایش اعتبار',
  transferBalance: 'انتقال اعتبار',
  signCustomApplication: 'ساین برنامه‌ی دلخواه',
  mb: 'مگابایت',
  gb: 'گیگابایت',
  pleaseChooseYourLanguage: 'لطفا زبان خود را انتخاب کنید',
  toManageLanguageGoToProfile:
    'برای مدیریت تنظیمات زبان میتوانید به پروفایل خود مراجعه کنید',
  english: 'English',
  persian: 'فارسی',
  arabian: 'عربی',
  with: 'با',
  download: 'دانلود',
  view: 'مشاهده',
  developerPanel: 'پنل توسعه‌دهندگان',
  inviteFriends: 'دعوت دوستان',
  makeMoney: 'کسب درآمد',
  changeLanguage: 'تغییر زبان',
  placeholders: {
    identifier: 'شماره تلفن یا ایمیل',
    password: 'رمز عبور',
  },
  validation: {
    empty: 'این فیلد نباید خالی باشد',
    wrongEmail: 'ایمیل نامعتبر است',
    wrongMobile: 'شماره تلفن نامعتبر است',
  },
  light: 'روشن',
  dark: 'تیره',
  system: 'سیستم',
  pleaseChooseTheme: 'لطفا قالب مورد نظر خود را انتخاب کنید',
  pleaseSelectOneOftheThemes: 'برای تغییر قالب یکی از گزینه ها را انتخاب کنید',
};
