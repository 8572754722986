import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import AuthenticatedRoute from 'HOCs/AuthenticateRoute';
import UnauthenticatedRoute from 'HOCs/UnauthenticatedRoute';
import ScrollToTop from './HOCs/ScrollToTop';
import WithTracker from 'HOCs/WithTracker';
import Loading from 'components/Loading';
import { renewToken } from './services/auth/actions';
import FourOFour from 'components/FourOFour';
import Pay from 'screens/Pay';
import Auth from 'screens/Auth';
import Profile from 'screens/Profile';
import Subscribe from 'screens/Subscribe';

function App() {
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const agent = useSelector((state) => state.agent);
  const dispatch = useDispatch();

  if (auth.error) return <Loading error={auth.error} retry={() => dispatch(renewToken())} />;
  return (
    <WithTracker>
      <UserAgentProvider ua={agent.userAgent}>
        <ScrollToTop>
          <ToastContainer
            closeButton={false}
            rtl
            bodyClassName='font-family-dana'
            draggablePercent={40}
            position='top-right'
          />
          <Helmet>
            <html lang={i18n.language} dir={i18n.dir()} />
          </Helmet>
          <Switch>
            <AuthenticatedRoute
              path='/profile'
              isAuthenticated={auth.isAuthenticated}
              redirect='/auth/login'
              component={Profile}
            />
            <UnauthenticatedRoute
              path='/auth'
              isAuthenticated={auth.isAuthenticated}
              redirect='/sub'
              component={Auth}
            />
            <AuthenticatedRoute
              path='/sub'
              isAuthenticated={auth.isAuthenticated}
              redirect='/auth/login'
              component={Subscribe}
            />
            <Route path='/pay' component={Pay} />
            <Route path='/sub' component={Subscribe} />
            <Route component={FourOFour} />
          </Switch>
          <Route exact path='/' render={() => <Redirect to='/auth/login' />} />
        </ScrollToTop>
      </UserAgentProvider>
    </WithTracker>
  );
}

export default App;
