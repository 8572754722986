import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import createRootReducer from "./reducers";

export default function store() {
  // Create a history depending on the environment
  const history = createBrowserHistory();

  const enhancers = [];
  const middlewares = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    // log actions in console
    // middlewares.push(createLogger());

    // Dev tools are helpful
    const __REDUX_DEVTOOLS_EXTENSION__ = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === "function") {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = {
    agent: { userAgent: window.navigator.userAgent },
    ...window.preloadedState,
  };

  // Create the store
  const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );

  return {
    store,
    history,
  };
}
