export default {
  storeName: 'Anardoni',
  facebook: 'Facebook',
  telegram: 'Telegram',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  copyLink: 'Copy link',
  copyLinkWithInvitationCode: 'Copy link with Invitation code',
  copied: 'Copied',
  reportBugInApplication: 'Report bug in application',
  balanceCurrency: 'ANA',
  addBalance: 'Charge',
  transferBalance: 'Transfer Balance',
  signCustomApplication: 'Sign Custom Application',
  mb: 'MB',
  gb: 'GB',
  pleaseChooseYourLanguage: 'Please choose your language',
  toManageLanguageGoToProfile:
    'To manage language settings go to profile section',
  english: 'English',
  persian: 'فارسی',
  arabian: 'عربی',
  with: 'With',
  download: 'Download',
  view: 'View',
  developerPanel: 'Developer panel',
  inviteFriends: 'Invite friends',
  makeMoney: 'Make money',
  changeLanguage: 'Change language',
  placeholders: {
    identifier: 'Phone number OR Email',
    password: 'Password',
  },
  validation: {
    empty: 'This field must not be empty',
    wrongEmail: 'Email is not valid',
    wrongMobile: 'Mobile is not valid',
  },
  light: 'Light',
  dark: 'Dark',
  system: 'System',
  pleaseChooseTheme: 'Please choose theme',
  pleaseSelectOneOftheThemes: 'Please choose one of the themes',
};
