import React from 'react';
import { toast } from 'react-toastify';
import keys from 'lodash/keys';

const ApiError = function APIError(
  error,
  actions = {},
  type = 'error',
  isNetwork = true,
) {
  const statusCodes = keys(actions);
  if (!isNetwork)
    return toast[type](
      <h3>
        مشکل غیر منتظره‌ای پیش اومده
        <span role='img' aria-label='img'>
          {' 🤒 '}
        </span>
        لطفا ما رو ببخشید و دوباره تلاش کنید.
        <hr className='my-2' />
        گزارش: <br />
        <div className='ltr text-left mt-1'>
          <code className='text-white'>{error.toString()}</code>
        </div>
      </h3>,
    );

  if (!error.response) return toast[type]('مشکلی در اتصال پیش آمده است');

  if (statusCodes.includes(error.response.status.toString())) {
    actions[error.response.status].forEach((action) =>
      typeof action === 'function' ? action(error) : toast[type](action),
    );
  } else {
    toast[type](
      <h3>
        مشکل غیر منتظره‌ای پیش اومده
        <span role='img' aria-label='img'>
          {' 🤒 '}
        </span>
        لطفا ما رو ببخشید و دوباره تلاش کنید.
        <hr className='my-2' />
        گزارش: <br />
        <div className='ltr text-left mt-1'>
          <code className='text-white'>{error.response.data.message}</code>
        </div>
      </h3>,
    );
  }
};

export default ApiError;
