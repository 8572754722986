import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function FourOFour(props) {
  useEffect(() => {
    /*
  Inspired by: "Error, 404"
  By: Sujeet Mishra
  Link: https://dribbble.com/shots/4571035-Error-404
*/

    let oh = document.querySelector('.circle.oh');

    document.addEventListener('mousemove', (event) => {
      let domainX = [0, document.body.clientWidth],
        domainY = [0, document.body.clientHeight],
        range = [-10, 10];

      let translate = {
        x:
          range[0] +
          ((event.clientX - domainX[0]) * (range[1] - range[0])) /
            (domainX[1] - domainX[0]),
        y:
          range[0] +
          ((event.clientY - domainY[0]) * (range[1] - range[0])) /
            (domainY[1] - domainY[0]),
      };

      oh.style.animation = 'none';
      oh.style.transform = `translate(${translate.x}px, ${translate.y}px)`;
    });

    document.addEventListener('mouseleave', (event) => {
      oh.style.animation = 'floating 3s linear infinite';
    });
  }, []);
  return (
    <section id='fourofour'>
      <div className='stars'>
        <style>
          {`
    body {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-image: -webkit-gradient(linear, left top, right bottom, from(#a9347e), to(#4b32a7));
      background-image: linear-gradient(to right bottom, #a9347e, #4b32a7);
      font-family: 'Open Sans', sans-serif;
      color: #fff;
   }
 
   #fourofour {
    width: 100vw;
    height: 85vh;
   }
    #fourofour .center {
      position: relative;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: -webkit-transform 0.5s;
      transition: -webkit-transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s;
      z-index: 1;
   }
    @media screen and (max-height: 600px) {
      #fourofour .center {
        -webkit-transform: translate(-50%, -50%) scale(0.75);
        transform: translate(-50%, -50%) scale(0.75);
     }
   }
    @media screen and (max-width: 400px) {
      #fourofour .center {
        -webkit-transform: translate(-50%, -50%) scale(0.6);
        transform: translate(-50%, -50%) scale(0.6);
     }
   }
    #fourofour .star {
      position: absolute;
      z-index: 1;
   }
    #fourofour .star:nth-child(1) {
      top: 6vh;
      left: 32vw;
   }
    #fourofour .star:nth-child(1):before, #fourofour .star:nth-child(1):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
   }
    #fourofour .star:nth-child(1):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(1):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(2) {
      top: 93vh;
      left: 55vw;
   }
    #fourofour .star:nth-child(2):before, #fourofour .star:nth-child(2):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -2s;
      animation-delay: -2s;
   }
    #fourofour .star:nth-child(2):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(2):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(3) {
      top: 94vh;
      left: 13vw;
   }
    #fourofour .star:nth-child(3):before, #fourofour .star:nth-child(3):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -3s;
      animation-delay: -3s;
   }
    #fourofour .star:nth-child(3):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(3):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(4) {
      top: 23vh;
      left: 72vw;
   }
    #fourofour .star:nth-child(4):before, #fourofour .star:nth-child(4):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -4s;
      animation-delay: -4s;
   }
    #fourofour .star:nth-child(4):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(4):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(5) {
      top: 99vh;
      left: 21vw;
   }
    #fourofour .star:nth-child(5):before, #fourofour .star:nth-child(5):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -5s;
      animation-delay: -5s;
   }
    #fourofour .star:nth-child(5):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(5):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(6) {
      top: 3vh;
      left: 19vw;
   }
    #fourofour .star:nth-child(6):before, #fourofour .star:nth-child(6):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -6s;
      animation-delay: -6s;
   }
    #fourofour .star:nth-child(6):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(6):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(7) {
      top: 59vh;
      left: 50vw;
   }
    #fourofour .star:nth-child(7):before, #fourofour .star:nth-child(7):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -7s;
      animation-delay: -7s;
   }
    #fourofour .star:nth-child(7):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(7):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(8) {
      top: 34vh;
      left: 63vw;
   }
    #fourofour .star:nth-child(8):before, #fourofour .star:nth-child(8):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -8s;
      animation-delay: -8s;
   }
    #fourofour .star:nth-child(8):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(8):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(9) {
      top: 34vh;
      left: 92vw;
   }
    #fourofour .star:nth-child(9):before, #fourofour .star:nth-child(9):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -9s;
      animation-delay: -9s;
   }
    #fourofour .star:nth-child(9):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(9):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(10) {
      top: 34vh;
      left: 95vw;
   }
    #fourofour .star:nth-child(10):before, #fourofour .star:nth-child(10):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -10s;
      animation-delay: -10s;
   }
    #fourofour .star:nth-child(10):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(10):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(11) {
      top: 78vh;
      left: 72vw;
   }
    #fourofour .star:nth-child(11):before, #fourofour .star:nth-child(11):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -11s;
      animation-delay: -11s;
   }
    #fourofour .star:nth-child(11):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(11):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(12) {
      top: 71vh;
      left: 64vw;
   }
    #fourofour .star:nth-child(12):before, #fourofour .star:nth-child(12):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -12s;
      animation-delay: -12s;
   }
    #fourofour .star:nth-child(12):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(12):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(13) {
      top: 48vh;
      left: 46vw;
   }
    #fourofour .star:nth-child(13):before, #fourofour .star:nth-child(13):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -13s;
      animation-delay: -13s;
   }
    #fourofour .star:nth-child(13):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(13):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(14) {
      top: 69vh;
      left: 14vw;
   }
    #fourofour .star:nth-child(14):before, #fourofour .star:nth-child(14):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -14s;
      animation-delay: -14s;
   }
    #fourofour .star:nth-child(14):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(14):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(15) {
      top: 61vh;
      left: 6vw;
   }
    #fourofour .star:nth-child(15):before, #fourofour .star:nth-child(15):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -15s;
      animation-delay: -15s;
   }
    #fourofour .star:nth-child(15):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(15):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(16) {
      top: 68vh;
      left: 44vw;
   }
    #fourofour .star:nth-child(16):before, #fourofour .star:nth-child(16):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -16s;
      animation-delay: -16s;
   }
    #fourofour .star:nth-child(16):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(16):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(17) {
      top: 91vh;
      left: 46vw;
   }
    #fourofour .star:nth-child(17):before, #fourofour .star:nth-child(17):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -17s;
      animation-delay: -17s;
   }
    #fourofour .star:nth-child(17):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(17):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(18) {
      top: 80vh;
      left: 55vw;
   }
    #fourofour .star:nth-child(18):before, #fourofour .star:nth-child(18):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -18s;
      animation-delay: -18s;
   }
    #fourofour .star:nth-child(18):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(18):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(19) {
      top: 27vh;
      left: 29vw;
   }
    #fourofour .star:nth-child(19):before, #fourofour .star:nth-child(19):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -19s;
      animation-delay: -19s;
   }
    #fourofour .star:nth-child(19):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(19):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(20) {
      top: 21vh;
      left: 6vw;
   }
    #fourofour .star:nth-child(20):before, #fourofour .star:nth-child(20):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -20s;
      animation-delay: -20s;
   }
    #fourofour .star:nth-child(20):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(20):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(21) {
      top: 48vh;
      left: 79vw;
   }
    #fourofour .star:nth-child(21):before, #fourofour .star:nth-child(21):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -21s;
      animation-delay: -21s;
   }
    #fourofour .star:nth-child(21):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(21):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(22) {
      top: 34vh;
      left: 48vw;
   }
    #fourofour .star:nth-child(22):before, #fourofour .star:nth-child(22):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -22s;
      animation-delay: -22s;
   }
    #fourofour .star:nth-child(22):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(22):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(23) {
      top: 82vh;
      left: 17vw;
   }
    #fourofour .star:nth-child(23):before, #fourofour .star:nth-child(23):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -23s;
      animation-delay: -23s;
   }
    #fourofour .star:nth-child(23):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(23):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(24) {
      top: 42vh;
      left: 37vw;
   }
    #fourofour .star:nth-child(24):before, #fourofour .star:nth-child(24):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -24s;
      animation-delay: -24s;
   }
    #fourofour .star:nth-child(24):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(24):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(25) {
      top: 62vh;
      left: 55vw;
   }
    #fourofour .star:nth-child(25):before, #fourofour .star:nth-child(25):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -25s;
      animation-delay: -25s;
   }
    #fourofour .star:nth-child(25):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(25):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(26) {
      top: 77vh;
      left: 17vw;
   }
    #fourofour .star:nth-child(26):before, #fourofour .star:nth-child(26):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -26s;
      animation-delay: -26s;
   }
    #fourofour .star:nth-child(26):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(26):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(27) {
      top: 63vh;
      left: 66vw;
   }
    #fourofour .star:nth-child(27):before, #fourofour .star:nth-child(27):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -27s;
      animation-delay: -27s;
   }
    #fourofour .star:nth-child(27):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(27):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(28) {
      top: 1vh;
      left: 96vw;
   }
    #fourofour .star:nth-child(28):before, #fourofour .star:nth-child(28):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -28s;
      animation-delay: -28s;
   }
    #fourofour .star:nth-child(28):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(28):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(29) {
      top: 51vh;
      left: 45vw;
   }
    #fourofour .star:nth-child(29):before, #fourofour .star:nth-child(29):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -29s;
      animation-delay: -29s;
   }
    #fourofour .star:nth-child(29):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(29):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(30) {
      top: 51vh;
      left: 93vw;
   }
    #fourofour .star:nth-child(30):before, #fourofour .star:nth-child(30):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -30s;
      animation-delay: -30s;
   }
    #fourofour .star:nth-child(30):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(30):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(31) {
      top: 85vh;
      left: 11vw;
   }
    #fourofour .star:nth-child(31):before, #fourofour .star:nth-child(31):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -31s;
      animation-delay: -31s;
   }
    #fourofour .star:nth-child(31):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(31):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(32) {
      top: 9vh;
      left: 56vw;
   }
    #fourofour .star:nth-child(32):before, #fourofour .star:nth-child(32):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -32s;
      animation-delay: -32s;
   }
    #fourofour .star:nth-child(32):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(32):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(33) {
      top: 94vh;
      left: 97vw;
   }
    #fourofour .star:nth-child(33):before, #fourofour .star:nth-child(33):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -33s;
      animation-delay: -33s;
   }
    #fourofour .star:nth-child(33):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(33):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(34) {
      top: 29vh;
      left: 1vw;
   }
    #fourofour .star:nth-child(34):before, #fourofour .star:nth-child(34):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -34s;
      animation-delay: -34s;
   }
    #fourofour .star:nth-child(34):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(34):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(35) {
      top: 61vh;
      left: 14vw;
   }
    #fourofour .star:nth-child(35):before, #fourofour .star:nth-child(35):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -35s;
      animation-delay: -35s;
   }
    #fourofour .star:nth-child(35):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(35):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(36) {
      top: 84vh;
      left: 71vw;
   }
    #fourofour .star:nth-child(36):before, #fourofour .star:nth-child(36):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -36s;
      animation-delay: -36s;
   }
    #fourofour .star:nth-child(36):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(36):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(37) {
      top: 73vh;
      left: 34vw;
   }
    #fourofour .star:nth-child(37):before, #fourofour .star:nth-child(37):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -37s;
      animation-delay: -37s;
   }
    #fourofour .star:nth-child(37):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(37):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(38) {
      top: 76vh;
      left: 55vw;
   }
    #fourofour .star:nth-child(38):before, #fourofour .star:nth-child(38):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -38s;
      animation-delay: -38s;
   }
    #fourofour .star:nth-child(38):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(38):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(39) {
      top: 56vh;
      left: 77vw;
   }
    #fourofour .star:nth-child(39):before, #fourofour .star:nth-child(39):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -39s;
      animation-delay: -39s;
   }
    #fourofour .star:nth-child(39):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(39):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(40) {
      top: 77vh;
      left: 94vw;
   }
    #fourofour .star:nth-child(40):before, #fourofour .star:nth-child(40):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -40s;
      animation-delay: -40s;
   }
    #fourofour .star:nth-child(40):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(40):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(41) {
      top: 64vh;
      left: 96vw;
   }
    #fourofour .star:nth-child(41):before, #fourofour .star:nth-child(41):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -41s;
      animation-delay: -41s;
   }
    #fourofour .star:nth-child(41):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(41):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(42) {
      top: 95vh;
      left: 76vw;
   }
    #fourofour .star:nth-child(42):before, #fourofour .star:nth-child(42):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -42s;
      animation-delay: -42s;
   }
    #fourofour .star:nth-child(42):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(42):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(43) {
      top: 63vh;
      left: 23vw;
   }
    #fourofour .star:nth-child(43):before, #fourofour .star:nth-child(43):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -43s;
      animation-delay: -43s;
   }
    #fourofour .star:nth-child(43):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(43):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(44) {
      top: 0vh;
      left: 91vw;
   }
    #fourofour .star:nth-child(44):before, #fourofour .star:nth-child(44):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -44s;
      animation-delay: -44s;
   }
    #fourofour .star:nth-child(44):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(44):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(45) {
      top: 21vh;
      left: 96vw;
   }
    #fourofour .star:nth-child(45):before, #fourofour .star:nth-child(45):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -45s;
      animation-delay: -45s;
   }
    #fourofour .star:nth-child(45):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(45):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(46) {
      top: 67vh;
      left: 45vw;
   }
    #fourofour .star:nth-child(46):before, #fourofour .star:nth-child(46):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -46s;
      animation-delay: -46s;
   }
    #fourofour .star:nth-child(46):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(46):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(47) {
      top: 98vh;
      left: 1vw;
   }
    #fourofour .star:nth-child(47):before, #fourofour .star:nth-child(47):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -47s;
      animation-delay: -47s;
   }
    #fourofour .star:nth-child(47):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(47):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(48) {
      top: 89vh;
      left: 77vw;
   }
    #fourofour .star:nth-child(48):before, #fourofour .star:nth-child(48):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -48s;
      animation-delay: -48s;
   }
    #fourofour .star:nth-child(48):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(48):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(49) {
      top: 19vh;
      left: 38vw;
   }
    #fourofour .star:nth-child(49):before, #fourofour .star:nth-child(49):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -49s;
      animation-delay: -49s;
   }
    #fourofour .star:nth-child(49):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(49):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(50) {
      top: 75vh;
      left: 75vw;
   }
    #fourofour .star:nth-child(50):before, #fourofour .star:nth-child(50):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -50s;
      animation-delay: -50s;
   }
    #fourofour .star:nth-child(50):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(50):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(51) {
      top: 74vh;
      left: 79vw;
   }
    #fourofour .star:nth-child(51):before, #fourofour .star:nth-child(51):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -51s;
      animation-delay: -51s;
   }
    #fourofour .star:nth-child(51):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(51):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(52) {
      top: 86vh;
      left: 98vw;
   }
    #fourofour .star:nth-child(52):before, #fourofour .star:nth-child(52):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -52s;
      animation-delay: -52s;
   }
    #fourofour .star:nth-child(52):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(52):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(53) {
      top: 19vh;
      left: 94vw;
   }
    #fourofour .star:nth-child(53):before, #fourofour .star:nth-child(53):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -53s;
      animation-delay: -53s;
   }
    #fourofour .star:nth-child(53):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(53):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(54) {
      top: 89vh;
      left: 75vw;
   }
    #fourofour .star:nth-child(54):before, #fourofour .star:nth-child(54):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -54s;
      animation-delay: -54s;
   }
    #fourofour .star:nth-child(54):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(54):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(55) {
      top: 84vh;
      left: 45vw;
   }
    #fourofour .star:nth-child(55):before, #fourofour .star:nth-child(55):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -55s;
      animation-delay: -55s;
   }
    #fourofour .star:nth-child(55):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(55):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(56) {
      top: 4vh;
      left: 48vw;
   }
    #fourofour .star:nth-child(56):before, #fourofour .star:nth-child(56):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -56s;
      animation-delay: -56s;
   }
    #fourofour .star:nth-child(56):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(56):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(57) {
      top: 32vh;
      left: 4vw;
   }
    #fourofour .star:nth-child(57):before, #fourofour .star:nth-child(57):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -57s;
      animation-delay: -57s;
   }
    #fourofour .star:nth-child(57):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(57):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(58) {
      top: 60vh;
      left: 56vw;
   }
    #fourofour .star:nth-child(58):before, #fourofour .star:nth-child(58):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -58s;
      animation-delay: -58s;
   }
    #fourofour .star:nth-child(58):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(58):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(59) {
      top: 5vh;
      left: 63vw;
   }
    #fourofour .star:nth-child(59):before, #fourofour .star:nth-child(59):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -59s;
      animation-delay: -59s;
   }
    #fourofour .star:nth-child(59):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(59):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(60) {
      top: 39vh;
      left: 54vw;
   }
    #fourofour .star:nth-child(60):before, #fourofour .star:nth-child(60):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -60s;
      animation-delay: -60s;
   }
    #fourofour .star:nth-child(60):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(60):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(61) {
      top: 31vh;
      left: 2vw;
   }
    #fourofour .star:nth-child(61):before, #fourofour .star:nth-child(61):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -61s;
      animation-delay: -61s;
   }
    #fourofour .star:nth-child(61):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(61):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(62) {
      top: 65vh;
      left: 99vw;
   }
    #fourofour .star:nth-child(62):before, #fourofour .star:nth-child(62):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -62s;
      animation-delay: -62s;
   }
    #fourofour .star:nth-child(62):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(62):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(63) {
      top: 39vh;
      left: 46vw;
   }
    #fourofour .star:nth-child(63):before, #fourofour .star:nth-child(63):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -63s;
      animation-delay: -63s;
   }
    #fourofour .star:nth-child(63):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(63):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(64) {
      top: 98vh;
      left: 98vw;
   }
    #fourofour .star:nth-child(64):before, #fourofour .star:nth-child(64):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -64s;
      animation-delay: -64s;
   }
    #fourofour .star:nth-child(64):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(64):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(65) {
      top: 36vh;
      left: 68vw;
   }
    #fourofour .star:nth-child(65):before, #fourofour .star:nth-child(65):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -65s;
      animation-delay: -65s;
   }
    #fourofour .star:nth-child(65):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(65):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(66) {
      top: 21vh;
      left: 97vw;
   }
    #fourofour .star:nth-child(66):before, #fourofour .star:nth-child(66):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -66s;
      animation-delay: -66s;
   }
    #fourofour .star:nth-child(66):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(66):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(67) {
      top: 12vh;
      left: 82vw;
   }
    #fourofour .star:nth-child(67):before, #fourofour .star:nth-child(67):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -67s;
      animation-delay: -67s;
   }
    #fourofour .star:nth-child(67):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(67):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(68) {
      top: 43vh;
      left: 40vw;
   }
    #fourofour .star:nth-child(68):before, #fourofour .star:nth-child(68):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -68s;
      animation-delay: -68s;
   }
    #fourofour .star:nth-child(68):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(68):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(69) {
      top: 94vh;
      left: 2vw;
   }
    #fourofour .star:nth-child(69):before, #fourofour .star:nth-child(69):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -69s;
      animation-delay: -69s;
   }
    #fourofour .star:nth-child(69):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(69):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(70) {
      top: 76vh;
      left: 19vw;
   }
    #fourofour .star:nth-child(70):before, #fourofour .star:nth-child(70):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -70s;
      animation-delay: -70s;
   }
    #fourofour .star:nth-child(70):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(70):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(71) {
      top: 59vh;
      left: 47vw;
   }
    #fourofour .star:nth-child(71):before, #fourofour .star:nth-child(71):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -71s;
      animation-delay: -71s;
   }
    #fourofour .star:nth-child(71):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(71):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(72) {
      top: 25vh;
      left: 82vw;
   }
    #fourofour .star:nth-child(72):before, #fourofour .star:nth-child(72):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -72s;
      animation-delay: -72s;
   }
    #fourofour .star:nth-child(72):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(72):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(73) {
      top: 85vh;
      left: 94vw;
   }
    #fourofour .star:nth-child(73):before, #fourofour .star:nth-child(73):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -73s;
      animation-delay: -73s;
   }
    #fourofour .star:nth-child(73):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(73):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(74) {
      top: 45vh;
      left: 33vw;
   }
    #fourofour .star:nth-child(74):before, #fourofour .star:nth-child(74):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -74s;
      animation-delay: -74s;
   }
    #fourofour .star:nth-child(74):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(74):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(75) {
      top: 49vh;
      left: 75vw;
   }
    #fourofour .star:nth-child(75):before, #fourofour .star:nth-child(75):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -75s;
      animation-delay: -75s;
   }
    #fourofour .star:nth-child(75):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(75):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .star:nth-child(76) {
      top: 68vh;
      left: 59vw;
   }
    #fourofour .star:nth-child(76):before, #fourofour .star:nth-child(76):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -76s;
      animation-delay: -76s;
   }
    #fourofour .star:nth-child(76):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(76):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(77) {
      top: 90vh;
      left: 15vw;
   }
    #fourofour .star:nth-child(77):before, #fourofour .star:nth-child(77):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -77s;
      animation-delay: -77s;
   }
    #fourofour .star:nth-child(77):before {
      top: 2px;
      left: -2px;
      width: 12px;
      height: 4px;
   }
    #fourofour .star:nth-child(77):after {
      top: -2px;
      left: 2px;
      width: 4px;
      height: 12px;
   }
    #fourofour .star:nth-child(78) {
      top: 13vh;
      left: 21vw;
   }
    #fourofour .star:nth-child(78):before, #fourofour .star:nth-child(78):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -78s;
      animation-delay: -78s;
   }
    #fourofour .star:nth-child(78):before {
      top: 1px;
      left: -1px;
      width: 6px;
      height: 2px;
   }
    #fourofour .star:nth-child(78):after {
      top: -1px;
      left: 1px;
      width: 2px;
      height: 6px;
   }
    #fourofour .star:nth-child(79) {
      top: 27vh;
      left: 92vw;
   }
    #fourofour .star:nth-child(79):before, #fourofour .star:nth-child(79):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -79s;
      animation-delay: -79s;
   }
    #fourofour .star:nth-child(79):before {
      top: 0.5px;
      left: -0.5px;
      width: 3px;
      height: 1px;
   }
    #fourofour .star:nth-child(79):after {
      top: -0.5px;
      left: 0.5px;
      width: 1px;
      height: 3px;
   }
    #fourofour .star:nth-child(80) {
      top: 67vh;
      left: 5vw;
   }
    #fourofour .star:nth-child(80):before, #fourofour .star:nth-child(80):after {
      position: absolute;
      content: '';
      background-color: #fff;
      border-radius: 10px;
      -webkit-animation: blink 1.5s infinite;
      animation: blink 1.5s infinite;
      -webkit-animation-delay: -80s;
      animation-delay: -80s;
   }
    #fourofour .star:nth-child(80):before {
      top: 1.5px;
      left: -1.5px;
      width: 9px;
      height: 3px;
   }
    #fourofour .star:nth-child(80):after {
      top: -1.5px;
      left: 1.5px;
      width: 3px;
      height: 9px;
   }
    #fourofour .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 50%;
   }
    #fourofour .circle--outer {
      width: 550px;
      height: 550px;
      background-image: -webkit-gradient(linear, left top, right bottom, from(#802b89), to(#5631a1));
      background-image: linear-gradient(to right bottom, #802b89, #5631a1);
      box-shadow: 0px 0px 20px -8px #000;
   }
    #fourofour .circle--inner {
      width: 355px;
      height: 355px;
      background-image: -webkit-gradient(linear, left top, right bottom, from(#75278b), to(#5530a2));
      background-image: linear-gradient(to right bottom, #75278b, #5530a2);
      box-shadow: 0px 0px 20px -8px #000;
   }
    #fourofour .four {
      position: absolute;
      font-size: 150px;
      font-weight: 900;
      text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   }
    #fourofour .four--1 {
      top: 100px;
      left: 30px;
      z-index: 1;
   }
    #fourofour .four--2 {
      top: 100px;
      left: 230px;
      z-index: 3;
   }
    #fourofour .astronaut {
      position: absolute;
      top: -115px;
      left: -30px;
      -webkit-transform: rotate(17deg);
      transform: rotate(17deg);
   }
    #fourofour .astronaut .astronaut-backpack {
      width: 100px;
      height: 100px;
      background-color: #e3e3e3;
      border-radius: 10px;
   }
    #fourofour .astronaut .antena--left {
      left: 10px;
   }
    #fourofour .astronaut .antena--right {
      right: 10px;
   }
    #fourofour .astronaut .astronaut-antena {
      position: absolute;
      top: -7px;
   }
    #fourofour .astronaut .astronaut-antena .ear-down {
      position: absolute;
      bottom: -5px;
      width: 15px;
      height: 20px;
      border-radius: 5px;
      background-color: #afafaf;
      z-index: 1;
   }
    #fourofour .astronaut .astronaut-antena .ear-up {
      position: relative;
      width: 15px;
      height: 20px;
      border-radius: 5px;
      background-color: #c3c3c3;
      z-index: 2;
   }
    #fourofour .astronaut .astronaut-antena .antena {
      position: absolute;
      top: -29px;
      width: 2px;
      height: 30px;
      border-radius: 5px;
      background-color: #c3c3c3;
   }
    #fourofour .astronaut .astronaut-antena .antena-tip {
      position: absolute;
      top: -33px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #c3c3c3;
   }
    #fourofour .astronaut .antena--left .antena {
      left: 5px;
   }
    #fourofour .astronaut .antena--right .antena {
      right: 5px;
   }
    #fourofour .astronaut .antena--left .antena-tip {
      left: 3px;
   }
    #fourofour .astronaut .antena--right .antena-tip {
      right: 3px;
   }
    #fourofour .astronaut .astronaut-helmet {
      position: absolute;
      top: -30px;
      left: 50%;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 5px 1px -3px #808080;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 3;
      overflow: hidden;
   }
    #fourofour .astronaut .astronaut-glass {
      position: absolute;
      top: 13px;
      left: 5px;
      width: 56px;
      height: 56px;
      background: #00202e;
      border-radius: 10px;
   }
    #fourofour .astronaut .glow {
      position: absolute;
      border-radius: 50%;
      background-color: #334d57;
   }
    #fourofour .astronaut .glow--1 {
      top: 25px;
      left: 15px;
      width: 17px;
      height: 17px;
   }
    #fourofour .astronaut .glow--2 {
      top: 44px;
      left: 13px;
      width: 7px;
      height: 7px;
   }
    #fourofour .astronaut .astronaut-body {
      position: absolute;
      top: 30px;
      left: 50%;
      width: 50px;
      height: 50px;
      background-color: #c3c3c3;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
   }
    #fourofour .astronaut-wire {
      position: absolute;
      top: -40px;
      left: -260px;
   }
    #fourofour .astronaut-hands {
      position: absolute;
      top: -89px;
      left: -23px;
      width: 100px;
      -webkit-transform: rotate(17deg);
      transform: rotate(17deg);
      z-index: 3;
   }
    #fourofour .astronaut-hands .astronaut-hand {
      position: absolute;
   }
    #fourofour .astronaut-hands .hand--left {
      left: -3px;
   }
    #fourofour .astronaut-hands .hand--right {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      right: -3px;
   }
    #fourofour .oh {
      -webkit-transition: all 0.1s linear;
      transition: all 0.1s linear;
      -webkit-animation: floating 4s linear infinite;
      animation: floating 4s linear infinite;
      z-index: 2;
   }
    #fourofour .planet {
      background-color: #ff4980;
      box-shadow: inset -6px -10px 0px 1px #cc3b6b, 0px 2px 10px rgba(0, 0, 0, 0.4);
      width: 155px;
      height: 155px;
   }
    #fourofour .craters .crater {
      position: absolute;
      border-radius: 50%;
      background-color: #c93667;
   }
    #fourofour .craters .crater--1 {
      width: 35px;
      height: 35px;
      top: 30px;
      left: 25px;
   }
    #fourofour .craters .crater--2 {
      width: 23px;
      height: 23px;
      top: 20px;
      left: 93px;
   }
    #fourofour .craters .crater--3 {
      width: 17px;
      height: 17px;
      top: 77px;
      left: 17px;
   }
    #fourofour .craters .crater--4 {
      width: 10px;
      height: 10px;
      top: 90px;
      left: 80px;
   }
    #fourofour .craters .crater--5 {
      width: 25px;
      height: 25px;
      top: 115px;
      left: 50px;
   }
    #fourofour .craters .crater--6 {
      width: 13px;
      height: 13px;
      top: 118px;
      left: 105px;
   }
    #fourofour .button {
      position: absolute;
      bottom: 30px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      padding: 8px 12px;
      border: 1px solid #fff;
      border-radius: 10px;
      cursor: pointer;
      color: #fff;
   }
    #fourofour .button:active {
      -webkit-transform: translateX(-50%) scale(0.95);
      transform: translateX(-50%) scale(0.95);
   }
    #fourofour .sorry {
      position: absolute;
      top: 210px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      font-size: 15px;
   }
    @-webkit-keyframes blink {
      0%, 100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
     }
      50% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0.5;
     }
   }
    @keyframes blink {
      0%, 100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
     }
      50% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0.5;
     }
   }
    @-webkit-keyframes floating {
      0%, 100% {
        -webkit-transform: translate(1px, 0px) rotate(0deg);
        transform: translate(1px, 0px) rotate(0deg);
     }
      20% {
        -webkit-transform: translate(3px, 1px) rotate(2deg);
        transform: translate(3px, 1px) rotate(2deg);
     }
      40% {
        -webkit-transform: translate(0px, 2px) rotate(1deg);
        transform: translate(0px, 2px) rotate(1deg);
     }
      60% {
        -webkit-transform: translate(-2px, -2px) rotate(2deg);
        transform: translate(-2px, -2px) rotate(2deg);
     }
      80% {
        -webkit-transform: translate(-1px, 3px) rotate(0deg);
        transform: translate(-1px, 3px) rotate(0deg);
     }
   }
    @keyframes floating {
      0%, 100% {
        -webkit-transform: translate(1px, 0px) rotate(0deg);
        transform: translate(1px, 0px) rotate(0deg);
     }
      20% {
        -webkit-transform: translate(3px, 1px) rotate(2deg);
        transform: translate(3px, 1px) rotate(2deg);
     }
      40% {
        -webkit-transform: translate(0px, 2px) rotate(1deg);
        transform: translate(0px, 2px) rotate(1deg);
     }
      60% {
        -webkit-transform: translate(-2px, -2px) rotate(2deg);
        transform: translate(-2px, -2px) rotate(2deg);
     }
      80% {
        -webkit-transform: translate(-1px, 3px) rotate(0deg);
        transform: translate(-1px, 3px) rotate(0deg);
     }
   }
    
    
    `}
        </style>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
      </div>
      <div className='center'>
        <div className='circle circle--outer'></div>
        <div className='circle circle--inner'>
          <div className='four four--1'>4</div>
          <div className='four four--2'>4</div>
          <div className='circle oh'>
            <div className='astronaut'>
              <div className='astronaut-backpack'></div>
              <div className='astronaut-head'></div>
              <div className='astronaut-antena antena--left'>
                <div className='ear-down'></div>
                <div className='ear-up'></div>
                <div className='antena'></div>
                <div className='antena-tip'></div>
              </div>
              <div className='astronaut-antena antena--right'>
                <div className='ear-down'></div>
                <div className='ear-up'></div>
                <div className='antena'></div>
                <div className='antena-tip'></div>
              </div>
              <div className='astronaut-helmet'>
                <div className='astronaut-glass'></div>
                <div className='glow glow--1'></div>
                <div className='glow glow--2'></div>
              </div>
              <div className='astronaut-body'></div>
            </div>
            <svg
              className='astronaut-wire'
              viewBox='-9 -9 259 823'
              width='259'
              height='823'>
              <path
                d='M241 0c-24 54-30 113-78 148S63 159 27 215c-35 55 32 102 73 141s103 94 98 166c-6 97-169 66-192 157-10 43-8 84 9 126'
                fill='none'
                stroke-width='6'
                stroke='#FFF'></path>
            </svg>
            <div className='circle planet'>
              <div className='craters'>
                <div className='crater crater--1'></div>
                <div className='crater crater--2'></div>
                <div className='crater crater--3'></div>
                <div className='crater crater--4'></div>
                <div className='crater crater--5'></div>
                <div className='crater crater--6'></div>
              </div>
            </div>
            <div className='astronaut-hands'>
              <div className='astronaut-hand hand--left'>
                <svg width='35' height='75'>
                  <path
                    d='M30.23 17.209c-7.925 5.118-11.657 12.786-11.226 22.975-7.113.934-12.948 4.345-18.44 5.117C-1.951 26.539 3.92 9.346 18.635 1.369 30.66-4.39 39.53 9.398 30.23 17.209z'
                    fill='#D2D2D2'></path>
                  <g fill='none' stroke='#999' stroke-linecap='round'>
                    <path d='M11.78 6.977c7.983.129 13.547 3.968 16.308 11.111M4.67 17.161c7.307-.379 13.1 1.924 17.93 6.94'></path>
                    <path
                      d='M.816 31.334c6.439-2.441 12.295-1.746 18.149 2.488'
                      stroke-linejoin='round'></path>
                  </g>
                  <g fill='#fff'>
                    <path d='M7.721 37.171c5.875-1.994 12.264 1.156 14.258 7.031l1.218 3.588c1.995 5.875-1.156 12.264-7.03 14.258-5.875 1.995-12.264-1.156-14.259-7.031L.69 51.429c-1.994-5.875 1.156-12.263 7.031-14.258z'></path>
                    <path d='M7.829 38.159c5.794-1.967 12.094 1.14 14.061 6.934l5.044 14.855c1.967 5.794-1.14 12.095-6.934 14.062-5.794 1.967-12.095-1.14-14.062-6.934L.895 52.221c-1.967-5.794 1.14-12.095 6.934-14.062z'></path>
                    <path d='M16.863 39.472l12.879 7.384a3.876 3.876 0 0 1 1.433 5.287 3.875 3.875 0 0 1-5.286 1.433l-12.878-7.384a3.874 3.874 0 0 1-1.434-5.286 3.874 3.874 0 0 1 5.286-1.434z'></path>
                  </g>
                </svg>
              </div>
              <div className='astronaut-hand hand--right'>
                <svg width='35' height='75'>
                  <path
                    d='M30.23 17.209c-7.925 5.118-11.657 12.786-11.226 22.975-7.113.934-12.948 4.345-18.44 5.117C-1.951 26.539 3.92 9.346 18.635 1.369 30.66-4.39 39.53 9.398 30.23 17.209z'
                    fill='#D2D2D2'></path>
                  <g fill='none' stroke='#999' stroke-linecap='round'>
                    <path d='M11.78 6.977c7.983.129 13.547 3.968 16.308 11.111M4.67 17.161c7.307-.379 13.1 1.924 17.93 6.94'></path>
                    <path
                      d='M.816 31.334c6.439-2.441 12.295-1.746 18.149 2.488'
                      stroke-linejoin='round'></path>
                  </g>
                  <g fill='#fff'>
                    <path d='M7.721 37.171c5.875-1.994 12.264 1.156 14.258 7.031l1.218 3.588c1.995 5.875-1.156 12.264-7.03 14.258-5.875 1.995-12.264-1.156-14.259-7.031L.69 51.429c-1.994-5.875 1.156-12.263 7.031-14.258z'></path>
                    <path d='M7.829 38.159c5.794-1.967 12.094 1.14 14.061 6.934l5.044 14.855c1.967 5.794-1.14 12.095-6.934 14.062-5.794 1.967-12.095-1.14-14.062-6.934L.895 52.221c-1.967-5.794 1.14-12.095 6.934-14.062z'></path>
                    <path d='M16.863 39.472l12.879 7.384a3.876 3.876 0 0 1 1.433 5.287 3.875 3.875 0 0 1-5.286 1.433l-12.878-7.384a3.874 3.874 0 0 1-1.434-5.286 3.874 3.874 0 0 1 5.286-1.434z'></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <a className='button' href='/'>
            بازگشت به خانه
          </a>
        </div>
        <div className='sorry text-center'>
          چیزی که دنبالش بودید رو پیدا نکردیم.
        </div>
      </div>
    </section>
  );
}

export default FourOFour;
