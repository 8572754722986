import React from 'react';
import Rodal from 'rodal';
import { css } from 'glamor';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  dialog: {
    width: '22em',
    borderRadius: '15px',
    maxWidth: '330px',
    padding: '0',
    marginRight: '20px',
    marginLeft: '20px',
    overflowY: 'initial',
  },
};
const rules = {
  btn: css({ padding: '0.6rem 0 0.7rem 0 !important' }),
};

function Alert({
  visible,
  onClose,
  afterOnClose,
  onAccept,
  onCancel,
  icon,
  iconStyle = {},
  title,
  cancelText,
  acceptText,
  alert,
  description,
}) {
  const { t } = useTranslation();

  let Icon;
  if (typeof icon === 'function') Icon = icon;
  else Icon = () => <img className='alert-img' src={icon} alt='icon' />;

  return (
    <Rodal
      customStyles={modalStyle.dialog}
      visible={visible}
      onClose={() => {
        onClose();
        if (afterOnClose) afterOnClose();
      }}
      showCloseButton={false}
      duration={350}>
      <div className='text-center ltr'>
        {visible && <style>{'html, body, .rodal {overflow-y:hidden}'}</style>}
        <Icon
          className={'alert-img'}
          width='unset'
          height='unset'
          style={iconStyle}
        />
        <div className='py-4'>
          <div className='h2 text-black mb-3 px-2'>{title}</div>
          {alert && <div className='h3 mb-0'>{alert}</div>}
          <div className='px-4 h3 mb-0'>{description}</div>
        </div>

        {!onCancel && !onAccept && (
          <button
            className={`${rules.btn} btn-ahref btn-block divider-top font-weight-medium`}
            onClick={onClose}>
            {acceptText || t('common:accept')}
          </button>
        )}

        {(onCancel || onAccept) && (
          <div>
            <button
              className={`${rules.btn} btn-clean divider-top text-primary font-weight-medium w-50`}
              onClick={() => {
                onClose();
                if (onAccept) onAccept();
              }}>
              {acceptText || t('common:accept')}
            </button>

            <button
              className={`${rules.btn} btn-clean divider-top text-muted font-weight-medium divider-left w-50`}
              onClick={() => {
                onClose();
                if (onCancel) onCancel();
              }}>
              {cancelText || t('common:close')}
            </button>
          </div>
        )}
      </div>
    </Rodal>
  );
}

export default Alert;
