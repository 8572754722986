import React from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import httpStatus from "http-status";
import axios from "axios";
import classnames from "classnames";
import { TextField, PasswordField } from "components/Fields";
import handlePasswordValidation, { mobileRegExp } from "utils/validation";
import to from "utils/to";
import ApiError from "helpers/ApiError";

function AuthRegisterInfo({
  handleSubmit,
  submitting,
  pristine,
  formValues,
  ...props
}) {
  const onSubmit = async (values) => {
    const query = `mobile.number=${values.mobile}`;
    const [err, res] = await to(
      axios.get(`${process.env.REACT_APP_API_URL}/auth/exists?${query}`)
    );

    if (err) {
      return ApiError(err, {
        [httpStatus.NOT_FOUND]: [
          () => {
            props.onSubmit(values);
          },
        ],
      });
    }
    if (res.data.existence) {
      const message = `${values.mobile} قبلا گرفته شده است`;
      toast.error(message);
      throw new SubmissionError({
        mobile: "قبلا گرفته شده است.",
      });
    }

    props.onSubmit(values);
  };

  return (
    <form noValidate className="curve" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Field
          name="mobile"
          containerClass="mb-2"
          ltr
          smallText="این شماره برای تصدیق هویت شما استفاده خواهد شد"
          type="tell"
          normalize={(value) => {
            if (!value) return value;

            const onlyNumbers = value.replace(/[^\d]/g, "");
            if (onlyNumbers[0] === "0") return onlyNumbers.slice(1);
            if (onlyNumbers[0] !== "9") return onlyNumbers.slice(1);
            else return onlyNumbers.slice(0, 10);
          }}
          className="input w-full input-bordered"
          placeholder="تلفن همراه"
          component={TextField}
        />
        <div className="col-lg-12 form-group">
          <div className="divide-x-2" />
        </div>

        <Field
          name="password"
          type="text"
          className="input w-full input-bordered"
          containerClass="mb-2"
          ltr
          placeholder="رمز عبور"
          component={PasswordField}
        />
        <Field
          name="confirmation"
          type="text"
          className="input w-full input-bordered"
          containerClass="mb-2"
          ltr
          placeholder="تایید رمز عبور"
          component={TextField}
        />
      </div>
      <button
        type="submit"
        name="submit"
        disabled={submitting || pristine}
        className={classnames("btn btn-primary btn-block", {
          "text-white bg-primary progress progress-bar-striped progress-bar-animated":
            submitting,
        })}
      >
        تایید
      </button>
    </form>
  );
}

function validate(values) {
  const names = ["mobile", "password", "confirmation"];
  const errors = {};
  for (let i = 0; i < names.length; i++) {
    if (!values[names[i]]) {
      errors[names[i]] = "این فیلد نباید خالی باشد";
    }
  }

  if (values.mobile && !mobileRegExp.test(values.mobile)) {
    errors.mobile = "شماره تلفن معتبر نیست";
  }
  if (values.password) {
    const { message } = handlePasswordValidation(values.password);
    errors.password = message;
  }
  if (
    values.password &&
    values.confirmation &&
    values.password !== values.confirmation
  ) {
    errors.confirmation = "با رمز عبور یکسان نیست";
  }
  return errors;
}

function mapStateToProps({ form }) {
  return { formValues: form?.register?.values };
}
export default connect(mapStateToProps)(
  reduxForm({
    form: "register",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
  })(AuthRegisterInfo)
);
