import jwtDecode from 'jwt-decode';
import httpStatus from 'http-status';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import { getToken } from './api';
import ApiError from 'helpers/ApiError';
import to from 'utils/to';

export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  user,
});

export const renewToken = () => async (dispatch) => {
  const [err, res] = await to(getToken());
  if (err) {
    dispatch({ type: SET_CURRENT_USER, error: err });

    ApiError(err, {
      [httpStatus.UNPROCESSABLE_ENTITY]: [
        () => {
          dispatch(logoutUser());
        },
      ],
    });
  } else {
    const payload = res.data;
    const { token } = payload;
    const user = jwtDecode(token);

    localStorage.setItem('jwtToken', token);
    setAuthorizationToken(token);
    dispatch(setCurrentUser({ ...user }));
  }
};

export const loginUser = (payload) => (dispatch) => {
  const { token } = payload;
  const user = jwtDecode(token);

  localStorage.setItem('jwtToken', token);
  setAuthorizationToken(token);
  dispatch(setCurrentUser({ ...user }));
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthorizationToken(false);
  dispatch(setCurrentUser({}));
};
