import * as React from 'react';

function SvgPlus(props) {
  return (
    <svg viewBox='0 0 44 44' width='1em' height='1em' {...props}>
      <path d='M22 0C9.8 0 0 9.8 0 22s9.8 22 22 22 22-9.8 22-22S34.2 0 22 0zm12 23c0 .6-.4 1-1 1h-8.5c-.3 0-.5.2-.5.5V33c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-8.5c0-.3-.2-.5-.5-.5H11c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h8.5c.3 0 .5-.2.5-.5V11c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v8.5c0 .3.2.5.5.5H33c.6 0 1 .4 1 1v2z' />
    </svg>
  );
}

export default SvgPlus;
