export default {
  notEnoughBalance: 'رصيد محفظتك لا يكفي',
  increaseYourBalance: `رصيد حسابك الحالي هو {{balance}} ، يرجى شحن محفظتك أولاً ثم اتخاذ إجراء`,
  chargeBalance: 'رصيد الرسوم',
  inviteFriends: 'دعوة الأصدقاء',
  registerNewUdid: 'سجل رقم تعريف جديد',
  registering: 'التسجيل ...',
  price: 'السعر',
  lockedStatus: 'لقد تم استهلاك هذا الإيصال من قبل.',
  badRequestStatus: 'الإيصال غير صالح.',
  misdirectedRequestStatus:
    'مشكلة في الاتصال بخادم apple ، يرجى المحاولة مرة أخرى.',
};
