import React from 'react';
import classnames from 'classnames';
import Transition from 'react-transition-group/Transition';
import PlusIcon from 'assets/svg/Plus';
import CloseIcon from 'assets/svg/Close';

const defaultStyle = {
  transition: 'all 0.3s ease-in-out',
  maxHeight: '0px',
  overflow: 'hidden',
  opacity: 0,
};

const transitionStyles = {
  entering: { maxHeight: '0px', opacity: 0 },
  entered: {
    maxHeight: '1000px',
    opacity: '1',
  },
};

const Fade = ({ in: inProp, description }) => (
  <Transition in={inProp} timeout={0}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}>
        <div>{description}</div>
      </div>
    )}
  </Transition>
);
function Accordion(props) {
  const [isActive, setIsActive] = React.useState(!!props.isActive);

  React.useEffect(() => {
    setIsActive(!!props.isActive);
  }, [props.isActive]);

  const { title, description } = props;
  return (
    <div
      className={classnames(
        `mb-3 rounded-xl bg-base-100 bg-opacity-50 py-1 text-base-content backdrop-blur transition-all duration-100`,
        {
          active: isActive,
        },
      )}>
      <button
        className='btn-block'
        id={props.fragmentId}
        onClick={() => {
          setIsActive(!isActive);
        }}>
        <h1 className='mb-0 flex justify-between px-3 py-2 text-start align-middle font-bold'>
          <span>{title}</span>
          {isActive ? (
            <CloseIcon className='inline self-start' fill='currentColor' fillRule='evenodd' />
          ) : (
            <PlusIcon className='inline self-start' fill='currentColor' fillRule='evenodd' />
          )}
        </h1>
      </button>
      <Fade in={isActive} description={description} />
    </div>
  );
}

export default Accordion;
