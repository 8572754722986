import React, { useState } from 'react';
import classnames from 'classnames';
import Error from 'components/Error';
import to from 'utils/to';
import ApiError from 'helpers/ApiError';
import flag from 'country-code-emoji';
import { renewToken } from 'services/auth/actions';
import { useDispatch, useSelector } from 'react-redux';

import { getListResources, patchResource } from 'services/api';
import Container from './Container';

const LNGS = [
  { lng: 'en', name: 'English', local: 'us' },
  { lng: 'fa', name: 'فارسی', local: 'ir' },
];

function SelectSettings(props) {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [error, setError] = React.useState(null);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const loadAsyncData = async () => {
    setError(null);
    let err;
    let res;

    [err, res] = await to(
      getListResources({
        resource: 'currencies',
        queries: {},
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setCurrencies(res.data.results);
  };

  const onSubmitSettings = async () => {
    setError(false);
    setSubmitting(true);
    const [err] = await to(
      patchResource({
        resource: 'users',
        id: auth.user._id,
        data: {
          settings: {
            currency: selectedCurrency.code,
            language: selectedLanguage,
          },
        },
      }),
    );
    if (err) {
      setError(err);
      return ApiError(err);
    }
    await dispatch(renewToken());
    setSubmitting(false);
  };

  React.useEffect(() => {
    loadAsyncData();
  }, []);

  const renderSubmitButtonText = () => {
    if (submitting) return 'در حال ذخیره تنظیمات';
    if (selectedCurrency && selectedLanguage) return 'ذخیره تنظیمات';
    if (!selectedCurrency || !selectedLanguage) return 'ابتدا انتخاب کنید';
  };

  if (error) return <Error error={error} />;
  if (!currencies) return null;

  return (
    <section className='relative flex h-dvh flex-col justify-center gap-5'>
      <img src='/img/logo_white.png' className='absolute left-0 top-0 -z-10 w-1/2 rounded-2xl opacity-45' alt='fonta' />
      <img
        src='/img/logo_white.png'
        className='absolute bottom-24 right-12 -z-10 w-1/2 rounded-2xl opacity-45'
        alt='fonta'
      />

      <div className='absolute -left-1/2 bottom-0 -z-10 w-full'>
        <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='#4581E9'
            d='M57.7,-31.8C67,-17.2,61.4,7.6,49.3,29.5C37.3,51.4,18.6,70.5,-1.2,71.3C-21.1,72,-42.3,54.3,-52.8,33.2C-63.4,12.2,-63.5,-12.3,-52.9,-27.6C-42.4,-42.9,-21.2,-49.2,1.5,-50C24.2,-50.9,48.4,-46.4,57.7,-31.8Z'
            transform='translate(100 100)'
          />
        </svg>
      </div>

      <div className='absolute -right-1/3 top-0 -z-10 w-full'>
        <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
          <path
            fill='#4581E9'
            d='M57.7,-31.8C67,-17.2,61.4,7.6,49.3,29.5C37.3,51.4,18.6,70.5,-1.2,71.3C-21.1,72,-42.3,54.3,-52.8,33.2C-63.4,12.2,-63.5,-12.3,-52.9,-27.6C-42.4,-42.9,-21.2,-49.2,1.5,-50C24.2,-50.9,48.4,-46.4,57.7,-31.8Z'
            transform='translate(100 100)'
          />
        </svg>
      </div>
      <div className='absolute -z-10 h-dvh w-dvw bg-white bg-opacity-60 backdrop-blur-sm'></div>
      <div className='flex flex-col gap-14'>
        <Container>
          <h1 className='mb-3 text-center font-bold'>واحد پولی را انتخاب کنید</h1>
          <div className='flex flex-wrap justify-center gap-2'>
            {[...currencies]?.map((c) => (
              <button
                className={classnames('btn justify-between', {
                  'btn-active': selectedCurrency?.code === c.code,
                })}
                onClick={() => setSelectedCurrency(c)}>
                <span>{c.name}</span>
                <span>{c.symbol}</span>
              </button>
            ))}
          </div>
        </Container>

        <Container>
          <h1 className='mb-3 text-center font-bold'>زبان را انتخاب کنید</h1>
          <div className='flex justify-center gap-2'>
            {LNGS.map(({ lng, name, local }) => (
              <button
                className={classnames('btn justify-between', {
                  'btn-active': selectedLanguage === lng,
                })}
                onClick={() => setSelectedLanguage(lng)}>
                <span>{flag(local)}</span>
                <span>{name}</span>
              </button>
            ))}
          </div>
        </Container>
      </div>

      <button
        className='btn btn-success fixed bottom-0 h-16 w-full rounded-b-none rounded-t-3xl'
        onClick={onSubmitSettings}
        disabled={!selectedCurrency || !selectedLanguage || submitting}>
        {renderSubmitButtonText()}
      </button>
    </section>
  );
}

export default SelectSettings;
