import React, { Component } from "react";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { css } from "glamor";
import DangerIcon from "assets/svg/Danger";
import Validation from "./components/Validation";
import Emoji from "./components/Emoji";

const rules = {
  ltr: css({
    right: "0.75rem",
    left: "unset",
    color: "red",
  }),
  rtl: css({
    right: "unset",
    left: "0.75rem",
    color: "red",
  }),
  svg: css({
    position: "absolute",
    color: "#c80000",
    top: "50%",
    cursor: "help",
    transform: "translateY(-50%)",
  }),
};

class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationIsVisible: false,
    };
    this.handleVisibility = this.handleVisibility.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleVisibility() {
    this.setState((prevState) => ({
      validationIsVisible: !prevState.validationIsVisible,
    }));
  }

  handleError() {
    const {
      meta: { error },
      ltr,
    } = this.props;
    if (!isEmpty(error)) {
      return (
        <DangerIcon
          className={classnames(rules.svg, {
            [`${rules.ltr}`]: ltr,
            [`${rules.rtl}`]: !ltr,
          })}
          fill="currentColor"
          fillRule="evenodd"
        />
      );
    }
  }

  render() {
    const {
      input,
      type,
      labelText,
      className,
      containerClass,
      placeholder,
      ltr,
      smallText,
      meta: { touched, error, active },
    } = this.props;

    return (
      <div className={containerClass}>
        {labelText && <label htmlFor={input.name}>{labelText}</label>}
        <div
          className={classnames("relative", {
            "alert-validate": !active && touched && error,
            ltr,
          })}
          dir={ltr ? "ltr" : "rtl"}
          data-validate={error}
        >
          <input
            {...input}
            type={type}
            placeholder={placeholder}
            className={classnames(className, {
              "input-error": touched && !isEmpty(error),
            })}
            onFocus={(e) => {
              input.onFocus(e);
              this.handleVisibility();
            }}
            onBlur={(e) => {
              input.onBlur(e);
              this.handleVisibility();
            }}
          />
          {active && <Emoji value={input.value} />}
          {!active && touched && this.handleError()}
        </div>
        {smallText && (
          <small className="text-secondary mt-1">{smallText}</small>
        )}
        <Validation
          value={input.value}
          visible={this.state.validationIsVisible}
        />
      </div>
    );
  }
}
export default PasswordField;
