import React from "react";
import { reduxForm, SubmissionError } from "redux-form";
import httpStatus from "http-status";
import classnames from "classnames";
import { connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { css } from "glamor";
import { useTranslation, withTranslation } from "react-i18next";
import Seo from "helpers/Seo";
import ApiError from "helpers/ApiError";
import LeftArrowIcon from "assets/svg/LeftArrow";
import to from "utils/to";
import handlePasswordValidation, { mobileRegExp } from "utils/validation";
import style from "./styles";
import Mobile from "./components/Mobile";

const rules = {
  header: css({
    borderRadius: "5px",
    padding: "45px",
    marginTop: "-75px",
    marginBottom: "60px",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14),0 7px 10px -5px rgba(255, 152, 0, 0.4)",
  }),
  content: css({
    " .form-control": {
      outline: "none",
      width: "100%",
      borderRadius: "8px",
      borderWidth: "2px",
    },
    ' button[type="submit"]': {
      borderRadius: "8px",
    },
  }),

  box: css({
    width: "100%",
    height: "300px",
    top: "-60px",
    borderRadius: "5px",
    boxShadow: "0 2px 30px rgba(0, 0, 0, 0.2)",
    background: "#fbfcee",
    position: "relative",
    overflow: "hidden",
    WebkitTransform: "translate3d(0, 0, 0)",
    transform: "translate3d(0, 0, 0)",
    ":after": {
      content: " ",
      display: "block",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to bottom, #ee88aa, rgba(221, 238, 255, 0) 80%, rgba(255, 255, 255, 0.5))",
      zIndex: "11",
      transform: "translate3d(0, 0, 0)",
    },
  }),
};

function Register(props) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const onSubmit = async (values) => {
    const data = {
      email: {
        address: values.email || values.mobile + "@fonta.org",
      },
      mobile: {
        number: values.mobile,
      },
      profile: {
        name: {
          first: values.firstName,
          last: values.lastName,
        },
      },
      password: {
        hash: values.password,
        confirmation: values.confirmation,
      },
    };

    const [err] = await to(
      axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, data)
    );
    if (err) {
      return ApiError(err, {
        [httpStatus.BAD_REQUEST]: [
          (err) => {
            if (err.response.data.message.search("email") > 0) {
              throw new SubmissionError({
                email: "ایمیل قبلا گرفته شده است.",
              });
            } else if (err.response.data.message.search("mobile") > 0) {
              throw new SubmissionError({
                mobile: "شماره قبلا گرفته شده است",
              });
            }
          },
        ],
      });
    }

    toast.success("ثبت نام با موفقیت انجام شد 😄");
    history.push("/auth/login");
  };

  return (
    <div className={rules.container}>
      <Seo title="ثبت نام" />
      <style>{style}</style>
      <div style={{ opacity: "0", height: "30px" }} />

      <div className="md:bg-base-200 md:shadow-xl max-w-[500px] p-8 md:rounded-lg relative register-form">
        <div className={rules.box}>
          <div className="wave -one" />
          <div className="wave -two" />
          <div className="wave -three" />
          <div className="title">Register</div>
        </div>
        <Mobile onSubmit={onSubmit} />

        <small className="block mt-2">
          با تکمیل فرآیند ثبت‌نام شما که
          <a href="https://fonta.org/terms" target="_blank" rel="noreferrer">
            {" "}
            قوانین استفاده{" "}
          </a>
          را مطالعه کرده و قبول دارید.
        </small>
      </div>
      <div className="text-center">
        <Link
          className="mt-2 text-underline dotted"
          style={{ fontSize: "0.8rem" }}
          to={"/auth/login" + location.search}
        >
          {t("auth.register.doNotHaveAccount")}
          <span className="mx-1" />
          <LeftArrowIcon
            className={classnames("inline", {
              [css({ transform: "rotateZ(180deg)" })]: i18n.dir() === "ltr",
            })}
            fill="currentColor"
            fillRule="evenodd"
          />
        </Link>
      </div>
    </div>
  );
}

function validate(values, props) {
  const names = ["mobile", "password", "confirmation"];
  const errors = {};
  for (let i = 0; i < names.length; i++) {
    if (!values[names[i]]) {
      errors[names[i]] = props.t("glossary:validation.empty");
    }
  }

  if (values.mobile && !mobileRegExp.test(values.mobile)) {
    errors.mobile = props.t("glossary:validation.empty");
  }
  if (values.password) {
    const { message } = handlePasswordValidation(values.password);
    errors.password = message;
  }
  if (
    values.password &&
    values.confirmation &&
    values.password !== values.confirmation
  ) {
    errors.confirmation = "با رمز عبور یکسان نیست";
  }
  return errors;
}

async function asyncValidate(values, dispatch, props, currentFieldName) {
  const previousErrors = props.asyncErrors;

  const query = isNaN(values[currentFieldName])
    ? `emails.primary.address=${values[currentFieldName]}`
    : `mobile.number=${values[currentFieldName]}`;

  const [err, res] = await to(
    axios.get(`${process.env.REACT_APP_API_URL}/auth/exists?${query}`)
  );

  if (err) {
    return ApiError(err, {
      [httpStatus.NOT_FOUND]: [() => {}],
    });
  }

  if (res.data.existence) {
    const message = `${values[currentFieldName]} قبلا گرفته شده است`;
    toast.error(message);
    // eslint-disable-next-line
    throw {
      ...previousErrors,
      [currentFieldName]: message,
    };
  }
}

const mapStateToProps = ({ form: { register: { values = {} } = {} } }) => ({
  formValues: values,
});

export default connect(mapStateToProps)(
  withTranslation()(
    reduxForm({
      form: "register",
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      validate,
      asyncValidate,
    })(Register)
  )
);
