export default `
  .wave {
	opacity: 0.4;
	position: absolute;
	top: 3%;
	left: 50%;
	background: #0af;
	width: 500px;
	height: 500px;
	margin-left: -250px;
	margin-top: -250px;
	-webkit-transform-origin: 50% 48%;
	transform-origin: 50% 48%;
	border-radius: 43%;
	-webkit-animation: drift 3000ms infinite linear;
	animation: drift 3000ms infinite linear;
  }
  
  .wave.-three {
	-webkit-animation: drift 5000ms infinite linear;
	animation: drift 5000ms infinite linear;
  }
  
  .wave.-two {
	-webkit-animation: drift 7000ms infinite linear;
	animation: drift 7000ms infinite linear;
	opacity: 0.1;
	background: yellow;
  }
  
  
  
  .title {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;
	line-height: 300px;
	text-align: center;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2em;
	font-size: 36px;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	text-indent: 0.3em;
  }
  
  @-webkit-keyframes drift {
	from {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	from {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  
  @keyframes drift {
	from {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	from {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
`;
