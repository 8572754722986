import React from "react";
import { css } from "glamor";
import classnames from "classnames";
import RefreshIcon from "assets/svg/Refresh";
import to from "utils/to";
import ApiError from "helpers/ApiError";

const rules = {
  button: css({
    border: "1px solid #a1a1a1",
    borderRadius: "50%",
    display: "flex",
    padding: "15px",
    background: "none",
  }),
  svg: css({
    fontSize: "2em",
    fill: "var(--gray)",
  }),
  centered: css({
    position: "absolute",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
  }),
};

function Error({
  className,
  retry = new Promise(() => window.location.reload()),
  centered,
}) {
  const onButtonClick = async () => {
    let err;
    let retryCounter = localStorage.retryCounter
      ? Number(localStorage.retryCounter)
      : 0;

    if (retryCounter > 3) {
      retryCounter = 0;
      localStorage.setItem("retryCounter", retryCounter);
      window.location.reload();
    } else {
      // TODO: make 0 after successful retry
      [err] = await to(retry());
      if (err) ApiError(err);
      retryCounter += 1;
      localStorage.setItem("retryCounter", retryCounter);
    }
  };

  return (
    <button
      onClick={onButtonClick}
      className={classnames("flex mx-auto", {
        [className]: className,
        [rules.centered]: centered,
      })}
    >
      <RefreshIcon className={rules.svg} />
    </button>
  );
}

export default Error;
