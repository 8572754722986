import React from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import MessageCard from "components/MessageCard";
import { ReactComponent as ExternalLinkIcon } from "assets/svg/external-link.svg";

function Pay() {
  const match = useRouteMatch();
  const history = useHistory();

  const handlePushProfile = () => history.push("/profile");

  const handlePushSub = () => history.push("/sub");

  const InstagramForHelp = () => (
    <div className="mt-10 text-center block">
      <p className="font-semibold">
        برای مشاهده آموزش اتصال اشتراک به اپلیکیشن پست زیر را در اینستاگرام ما
        ببینید، درصورت داشتن هرگونه مشکل در دایرکت با ما در ارتباط باشید
      </p>
      <a
        className="my-6 font-bold text-center gap-2 btn btn-primary"
        href="https://www.instagram.com/p/Ch9wm5ZM7Sy/"
        rel="noopenner noreferrer"
        target="_blank"
      >
        مشاهده آموزش{" "}
        <ExternalLinkIcon
          class="h-6 w-6"
          fill="currentColor"
          fillRule="evenodd"
        />
      </a>
    </div>
  );

  return (
    <div className="container">
      <div className="centered">
        <Route path={`${match.path}/done`}>
          <MessageCard
            success
            title={<div>اشتراک شما فعال شد</div>}
            description={
              <div>
                برای مشاهده جزییات اشتراک خود میتوانید به حساب کاربری مراجعه
                کنید
              </div>
            }
            toDo={<div className="text-primary">حساب کاربری</div>}
            handleToDo={handlePushProfile}
          />
          <InstagramForHelp />
        </Route>
        <Route path={`${match.path}/failed`}>
          <MessageCard
            error
            title={<div>پرداخت ناموفق بود</div>}
            description={
              <div>برای خرید مجدد میتوانید روی دکمه زیر کلیک کنید</div>
            }
            toDo={<div className="text-primary">خرید مجدد</div>}
            handleToDo={handlePushSub}
          />
          <InstagramForHelp />
        </Route>

        <Route
          path={`${match.path}/canceled`}
          render={() => (
            <MessageCard
              failed
              title={<div>پرداخت لغو شد</div>}
              description={
                <div>برای خرید مجدد میتوانید روی دکمه زیر کلیک کنید</div>
              }
              toDo={<div className="text-primary">خرید مجدد</div>}
              handleToDo={handlePushSub}
            />
          )}
        />

        <Route
          path={`${match.path}/mismatch`}
          render={() => (
            <MessageCard
              error
              title={<div>پرداخت ناموفق بود</div>}
              description={
                <div>مبلغ تا ۷۲ ساعت آینده به حساب شما مرجوع خواهد شد</div>
              }
              toDo={<div className="text-primary">خرید مجدد</div>}
              handleToDo={handlePushSub}
            />
          )}
        />
      </div>
    </div>
  );
}

export default Pay;
