import React, { Component } from "react";
import Transition from "react-transition-group/Transition";
import classnames from "classnames";
import CheckIcon from "assets/svg/Check";
import validation from "utils/validation";

const duration = 300;

const defaultStyle = {
  transition: `max-height ${duration}ms ease-in-out`,
};

const transitionStyles = {
  entering: { maxHeight: "215px" },
  entered: { maxHeight: "215px" },
};

export default class PasswordValidation extends Component {
  state = {
    letter: false,
    capital: false,
    number: false,
    progressBar: { bg: "", width: "" },
  };

  static getDerivedStateFromProps(nextProps) {
    return validation(nextProps.value);
  }

  render() {
    const { letter, capital, number, progressBar } = this.state;
    return (
      <Transition in={this.props.visible} timeout={duration}>
        {(state) => (
          <div
            className={classnames("password-validation row", {
              "pt-2": this.props.visible,
            })}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="w-full">
              <h4>رمز شما باید:</h4>
              <span className={classnames({ valid: letter, invalid: !letter })}>
                <CheckIcon
                  className="inline"
                  fill="currentColor"
                  fillRule="evenodd"
                />
                دارای حداقل ۸ کاراکتر یا بیشتر باشد
              </span>
              <span
                className={classnames({ valid: capital, invalid: !capital })}
              >
                <CheckIcon
                  className="inline"
                  fill="currentColor"
                  fillRule="evenodd"
                />
                شامل حروف بزرگ و کوچک باشد
              </span>
              <span className={classnames({ valid: number, invalid: !number })}>
                <CheckIcon
                  className="inline"
                  fill="currentColor"
                  fillRule="evenodd"
                />
                دارای حداقل یک عدد باشد
              </span>
              <div className="validation-bar mt-3">
                <h4>استحکام:</h4>
                <progress
                  className={`progress progress-primary w-25`}
                  value={25}
                  max="100"
                />
                <p className="comment">
                  از بکار بردن رمزهایی که داخل سایت دیگر استفاده کرده اید یا حدس
                  زدن آنها برای افراد راحت است بپرهیزید
                </p>
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
}
