import Accordion from 'components/Accordion';
import ApiError from 'helpers/ApiError';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { getListResources, patchResource } from 'services/api';
import Error from 'components/Error';
import { useDispatch, useSelector } from 'react-redux';
import to from 'utils/to';
import axios from 'axios';
import { ReactComponent as CheerUpIcon } from 'assets/svg/cheer-up-pana.svg';
import Container from 'components/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import SelectSettings from 'components/SelectSettings';
import { useTranslation } from 'react-i18next';
import flag from 'country-code-emoji';
import { renewToken } from 'services/auth/actions';
import toPersianDigits from 'utils/toPersianDigits';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LNGS = [
  { lng: 'en', name: 'English', local: 'us' },
  { lng: 'fa', name: 'فارسی', local: 'ir' },
];
const GATEWAYS = [
  { currency: 'IRT', name: 'شاپرک', id: 'shaprak' },
  { currency: 'IRT', name: 'کارت به کارت', id: 'card' },
];
function Subscribe() {
  const [copied, setCopied] = React.useState(false);
  const [items, setItems] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedGateway, setSelectedGateway] = React.useState(null);
  const { i18n, t } = useTranslation();
  const [currencies, setCurrencies] = React.useState(null);
  const dispatch = useDispatch();
  const [receipts, setReceipts] = React.useState(null);
  const [error, setError] = React.useState(null);
  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  const getValuesBeforeRender = (item) => {
    let periodPrice;
    let periodIndicator;
    switch (item.plan) {
      case 'bronze':
        periodPrice = item.price / 1;
        periodIndicator = '۱ ماهه';
        break;

      case 'silver':
        periodPrice = item.price / 3;
        periodIndicator = '۳ ماهه';
        break;

      case 'gold':
        periodPrice = item.price / 6;
        periodIndicator = '۶ ماهه';
        break;

      case 'platinum':
        periodPrice = item.price / 12;
        periodIndicator = '۱ ساله';
        break;

      default:
        break;
    }

    return {
      periodPrice,
      periodIndicator,
    };
  };

  const loadAsyncData = async () => {
    setError(null);
    let err;
    let res;

    [err, res] = await to(
      getListResources({
        resource: 'currencies',
        queries: {},
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setCurrencies(res.data.results);

    [err, res] = await to(
      getListResources({
        resource: 'receipts',
        queries: {
          _user: auth.user._id,
          status: 'available',
        },
      }),
    );

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setReceipts(res.data.results);

    if (!lodash.isEmpty(res.data.results)) {
      [err, res] = await to(
        getListResources({
          resource: 'items',
          queries: {
            source: 'web',
            type: 'consumable',
            sort: { price: -1 },
          },
        }),
      );
    } else {
      [err, res] = await to(
        getListResources({
          resource: 'items',
          queries: {
            source: 'web',
            type: 'subscription',
            sort: { price: -1 },
          },
        }),
      );
    }

    if (err) {
      setError(err);
      return ApiError(err);
    }
    setItems(res.data.results);
  };

  React.useEffect(() => {
    if (auth.isAuthenticated) loadAsyncData();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    let tid;

    if (copied) {
      tid = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(tid);
    };
  }, [copied]);

  const getPayment = async (item) => {
    if (!auth.isAuthenticated) return history.push('/auth/login');

    setSubmitting(true);
    const [err, res] = await to(axios.get(`${process.env.REACT_APP_API_URL}/pay/?_item=${item._id}&gateway=payping`));
    setSubmitting(false);

    if (err) return ApiError(err);

    window.location.href = res.data.invoice.url;
  };

  const onSubmitCurrency = async (currency) => {
    const [err] = await to(
      patchResource({
        resource: 'users',
        id: auth.user._id,
        data: {
          settings: {
            currency: currency.code,
          },
        },
      }),
    );
    if (err) return ApiError(err);
    await dispatch(renewToken());
  };

  const renderSubmitButtonText = () => {
    if (selectedGateway) return 'برگشت';
    if (submitting) return 'در حال انتقال به درگاه پرداخت...';
    if (!selectedItem) return 'لطفا یکی از اشتراک‌ها رو انتخاب کنید 👆';
    // if (!selectedGateway) return 'لطفا نحوه پرداخت رو انتخاب کنید 👇';
    return 'ادامه خرید';
  };
  if (error) return <Error centered retry={loadAsyncData} />;

  if (!auth.user?.settings?.currency || !auth.user?.settings?.language) return <SelectSettings />;

  return (
    <section
      className='h-full w-full pb-10 text-white'
      style={{
        backgroundAttachment: 'fixed',
        background: '#0b114d',
        background: '-webkit-linear-gradient(#4581e9,#356bce,#204ca5,#0b114d)',
        background: 'linear-gradient(#4581e9,#356bce,#204ca5,#0b114d)',
      }}>
      <div
        className='h-full w-full'
        style={{
          backgroundAttachment: 'fixed',
          background:
            'linear-gradient(180deg,rgba(0,0,55,.7) 0%,rgba(0,0,55,.7) .01%,rgba(13,12,134,0) 40.33%,rgba(0,0,55,0) 100%)',
        }}>
        <Container>
          <div className='flex items-center justify-between py-8'>
            <Link to='/profile'>
              <div className="flex items-center gap-2 font-['Lalezar'] text-lg">
                <img className='h-8 w-8 rounded-full' src='/apple-touch-icon.png' alt={auth?.user?.mobile?.number} />
                <span className='leading-normal'>{auth?.user?.mobile?.number}</span>
              </div>
            </Link>

            <div className='z-10 flex'>
              {/* <div className='dropdown dropdown-end'>
                <label tabIndex={0} className='btn btn-ghost rounded-btn px-1'>
                  <span className='text-xl'>
                    {flag(lodash.find(LNGS, { lng: i18n.language.toLowerCase() })?.local)}
                  </span>{' '}
                  {lodash.find(LNGS, { lng: i18n.language.toLowerCase() })?.name}
                </label>
                <ul
                  tabIndex={0}
                  className='menu dropdown-content z-[1] mt-4 w-52 rounded-box bg-base-100 p-2 text-black shadow'>
                  {LNGS.map(({ lng, name, local }) => (
                    <li>
                      <a href={`/?lng=${lng}`}>
                        {flag(local)} {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div className='mx-2' />

              <div className='dropdown dropdown-end'>
                <label tabIndex={0} className='btn btn-ghost gap-1 rounded-btn px-1'>
                  <span>{auth.user?.settings?.currency}</span>
                  <span>{'/'}</span>
                  <span>{lodash.find(currencies, { code: auth.user.settings.currency })?.symbol}</span>
                </label>
                <ul
                  tabIndex={0}
                  className='menu dropdown-content z-[1] mt-4 w-52 rounded-box bg-base-100 p-2 text-black shadow'>
                  {currencies?.map((currency) => (
                    <li>
                      <button className='flex justify-between' onClick={() => onSubmitCurrency(currency)}>
                        <span>{currency.name}</span>
                        <span>{currency.symbol}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <Container px={false} className='mb-10'>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={15}
            slidesPerView={3}
            slidesOffsetBefore={15}
            slidesOffsetAfter={15}
            autoplay={{
              delay: 1000,
            }}
            dir='rtl'
            direction='horizontal'>
            <SwiperSlide>
              <img src={`/img/slide_1.png`} className='w-full rounded-3xl' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={`/img/slide_2.png`} className='w-full rounded-3xl' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={`/img/slide_3.png`} className='w-full rounded-3xl' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={`/img/slide_4.png`} className='w-full rounded-3xl' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={`/img/slide_5.png`} className='w-full rounded-3xl' />
            </SwiperSlide>
          </Swiper>
        </Container>
        <Container>
          {/* <div>
            <h1 className='pb-6 text-center font-bold'>با خرید اشتراک ویژه فونتا چه ویژگی‌هایی دریافت میکنید؟</h1>
            <h2 className='text-center font-bold'>• حذف تبلیغات •</h2>
            <h2 className='text-center font-bold'>• حذف نیاز به فیلترشکن •</h2>
            <h2 className='text-center font-bold'>• خروجی گرفتن نامحدود •</h2>
            <div className='divider' />
          </div> */}

          {selectedGateway?.id === 'card' && (
            <div className='mb-8 text-center font-bold'>
              ابتدا مبلغ اشتراک مورد نظر خود را به شماره کارت زیر واریز کنید، سپس رسید را برای پشتیبان های ما ارسال
              کنید.
            </div>
          )}

          {selectedGateway?.id === 'card' && (
            <CopyToClipboard text={`6219861962562251`} onCopy={() => setCopied(true)}>
              <div className='mx-auto mb-8 rounded-3xl border-white bg-base-300 bg-opacity-30 px-6 py-8 text-center font-bold shadow-2xl backdrop-blur lg:w-3/4'>
                <h1 className='text-xl font-normal'>سعید شیخی</h1>
                <h2 className='mt-4 text-2xl'>{toPersianDigits('6219-8619-6256-2251')}</h2>

                <button className='btn mt-9 rounded-3xl bg-white px-8 text-black'>
                  {copied && 'کپی شد'}
                  {!copied && 'کپی شماره کارت'}
                </button>
              </div>
            </CopyToClipboard>
          )}
          {items?.map((item) => (
            <button
              disabled={submitting}
              className={classnames(
                'mb-3 flex w-full cursor-pointer items-center justify-between rounded-2xl bg-base-content bg-opacity-30 px-6 py-4 text-start shadow-xl backdrop-blur transition-all duration-100 hover:shadow-2xl',
                {
                  'border-primary bg-opacity-70': item.id === selectedItem?.id,
                },
              )}
              onClick={() => setSelectedItem(item)}>
              <span className='light:text-white flex flex-col font-semibold dark:text-white'>
                <span className='font-bold'>اشتراک {getValuesBeforeRender(item).periodIndicator}</span>
                <span className='text-sm'>
                  روزانه{' '}
                  {t('common:price', {
                    price: getValuesBeforeRender(item).periodPrice / 31,
                    currency: currencies.find((c) => c.code === auth.user.settings.currency),
                  })}
                </span>
              </span>
              <h1 className='light:text-white font-bold dark:text-white'>
                {t('common:price', {
                  price: item.price,
                  currency: currencies.find((c) => c.code === auth.user.settings.currency),
                })}
              </h1>
            </button>
          ))}

          <div className='mb-3 mt-1 text-center'>به مبالغ فوق ۱۰ درصد مالیات اضافه خواهد شد.</div>

          {selectedGateway?.id === 'card' && (
            <button
              className='btn btn-block rounded-xl font-bold disabled:text-white'
              onClick={() => setSelectedGateway(null)}>
              برگشت
            </button>
          )}

          {selectedGateway?.id !== 'card' && (
            <React.Fragment>
              <button
                className='btn btn-block rounded-xl font-bold disabled:text-white'
                disabled={submitting || !selectedItem}
                onClick={() => getPayment(selectedItem)}>
                {renderSubmitButtonText()}
              </button>
              <div className='mt-4 text-center font-bold'>
                در صورت تمایل میتوانید{' '}
                <button
                  className='text-warning'
                  onClick={() => setSelectedGateway(GATEWAYS.find((g) => g.id === 'card'))}>
                  کارت به کارت
                </button>{' '}
                کنید
              </div>
            </React.Fragment>
          )}

          {/* 
          <div className='flex grid-cols-2 gap-2'>
            {GATEWAYS?.map((gateway) => (
              <button
                disabled={submitting}
                className={classnames(
                  'mt-3 flex w-full cursor-pointer items-center justify-between rounded-2xl bg-base-content bg-opacity-30 px-6 py-4 text-start shadow-xl backdrop-blur transition-all duration-100 hover:shadow-2xl',
                  {
                    'border-primary bg-opacity-70': gateway.id === selectedGateway?.id,
                  },
                )}
                onClick={() => setSelectedGateway(gateway)}>
                <span className='light:text-white flex flex-col font-semibold dark:text-white'>
                  <span className='font-bold'>{gateway.name}</span>
                </span>
              </button>
            ))}
          </div> */}

          <CheerUpIcon />
          <h3 className='my-6 text-center text-xl font-bold'>
            درصورت داشتن هرگونه مشکل به دایرکت ما در اینستاگرام پیغام بفرستید
          </h3>
          <a
            className='btn btn-block my-6 rounded-xl bg-white text-black'
            href='https://instagram.com/fonta.fa'
            rel='noopenner noreferrer'
            target='_blank'>
            <h3 className='text-center font-bold'>اینستاگرام ما</h3>
          </a>

          <Accordion
            title='چجوری از فونتا داخل اپ‌های دیگه هم استفاده کنم؟'
            description={
              <div className='px-2 pb-3 pt-1 lg:px-3'>
                <p className='font-medium'>
                  میتونین به صورت png خروجی بگیرین و داخل گالری داشته باشینش، بعد داخل هر اپی که خواستین از تصویرش
                  میتونین استفاده کنین
                </p>
              </div>
            }
          />
          <Accordion
            title='تو نسخه اندروید چطوری میتونم کپی کنم؟'
            description={
              <div className='px-2 pb-3 pt-1 lg:px-3'>
                <p className='font-medium'>
                  تو نسخه اندروید میتونین به صورت png خروجی بگیرین، داخل گالری سیوش کنین بعد تو استوری ازش استفاده کنین
                </p>
              </div>
            }
          />
        </Container>
      </div>
    </section>
  );
}

export default Subscribe;
