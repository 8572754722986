import i18n from 'i18next';
import dayjs from 'dayjs';
import jalaliday from 'jalaliday';
import relativeTimeDayJsPlugin from 'dayjs/plugin/relativeTime';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nCommonFa, i18nCommonAr, i18nCommonEn } from './i18n/common';
import { i18nGlossaryFa, i18nGlossaryAr, i18nGlossaryEn } from './i18n/glossary';
import { seoI18nFa, seoI18nEn, seoI18nAr } from 'helpers/Seo/i18n';
import { authI18nFa, authI18nAr, authI18nEn } from 'screens/Auth/i18n';
import { subscriptionI18nAr, subscriptionI18nEn, subscriptionI18nFa } from 'components/Subscriptions/i18n';
import toPersianDigits from 'utils/toPersianDigits';
import renderDigits from 'helpers/renderDigits';

dayjs.extend(relativeTimeDayJsPlugin);
dayjs.extend(jalaliday);

const options = {
  // we init with resources
  resources: {
    fa: {
      translations: {
        auth: authI18nFa,
        subscription: subscriptionI18nFa,
        seo: seoI18nFa,
      },
      common: i18nCommonFa,
      glossary: i18nGlossaryFa,
    },
    en: {
      translations: {
        auth: authI18nEn,
        subscription: subscriptionI18nEn,
        seo: seoI18nEn,
      },
      common: i18nCommonEn,
      glossary: i18nGlossaryEn,
    },

    ar: {
      translations: {
        auth: authI18nAr,
        subscription: subscriptionI18nAr,
        seo: seoI18nAr,
      },
      common: i18nCommonAr,
      glossary: i18nGlossaryAr,
    },
  },
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'localStorage', 'cookie', 'htmlTag', 'navigator', 'path', 'subdomain'],

    // keys or params to lookup language from
    // lookupQuerystring: 'lng',
    lookupCookie: 'i18n',
    lookupLocalStorage: 'i18nLng',
  },

  fallbackLng: ['fa', 'en', 'ar'],
  // debug: process.env.NODE_ENV === 'development',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
};

i18n.on('languageChanged', function (lng) {
  dayjs.locale(lng);
  if (lng === 'fa') dayjs.calendar('jalali');
  else dayjs.calendar('gregory');
});

// for browser use xhr backend to load translations and browser lng detector
i18n.use(initReactI18next).use(LanguageDetector);

// initialize if not already initialized
if (!i18n.isInitialized)
  i18n.init(options, (err, t) => {
    i18n.services.formatter.add('toCurrency', (value, lng, options) => {
      if (!value) return '';
      const { currency } = options;
      return value * currency.rate;
    });
    i18n.services.formatter.add('toUnit', (value, lng, options) => {
      if (!value) return '';
      return i18n.t(`glossary:${options.item.unit}`);
    });
    i18n.services.formatter.add('renderDigits', (value, lng, options) => {
      if (!value) return '';
      const { currency } = options;
      return renderDigits(value, { maximumFractionDigits: currency.code === 'IRT' ? 0 : 2 });
    });
    i18n.services.formatter.add('dayjs', (value, lng, options) => {
      if (!value) return '';
      if (!dayjs(value).isValid()) return value;
      return dayjs(value).tz('Asia/Tehran').format(options.format);
    });
  });

export default i18n;
