import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { css } from "glamor";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import httpStatus from "http-status";
import axios from "axios";
import { withTranslation } from "react-i18next";
import Owl from "./components/Owl";
import Form from "./components/Form";
import Seo from "helpers/Seo";
import LeftArrowIcon from "assets/svg/LeftArrow";
import { loginUser } from "services/auth/actions";
import to from "utils/to";
import { emailRegExp, mobileRegExp } from "utils/validation";
import ApiError from "helpers/ApiError";

function Login({ t, i18n, ...props }) {
  const [isPassword, setIsPassword] = useState(false);

  const handlePasswordFocus = () => {
    setIsPassword(!isPassword);
  };

  const onSubmit = async (values) => {
    const [err, res] = await to(
      axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, values)
    );
    if (err) {
      toast.error(err);
      return ApiError(err, {
        [httpStatus.FORBIDDEN]: [
          "رمز ورودی نامعتبر است، لطفا دوباره بررسی کنید.",
          () => {
            throw new SubmissionError({
              password: "رمز نامعتبر است",
            });
          },
        ],
        [httpStatus.NOT_FOUND]: [
          "انارآیدی با این مشخصات پیدا نشد، لطفا ابتدا ثبت‌نام کنید.",
          () => {
            throw new SubmissionError({
              identifier: "انارآیدی با این مشخصات پیدا نشد",
            });
          },
        ],
      });
    }

    props.loginUser(res.data);
  };

  return (
    <div className="text-center">
      <div
        className="hidden sm:block"
        style={{ opacity: "0", height: "100px" }}
      />
      <Seo title={t("auth.login.title")} />
      <form
        className="mx-auto p-8 pt-0 w-[380px] mt-[110px] md:mt-0 md:pt-8 md:bg-base-200 md:shadow-lg md:border md:border-base-300 rounded-xl login-form-desktop"
        noValidate
        onSubmit={props.handleSubmit((values) => onSubmit(values))}
      >
        <Owl isPassword={isPassword} />
        <Form
          handlePasswordFocus={handlePasswordFocus}
          submitting={props.submitting}
          pristine={props.pristine}
        />
        {/* <Link
          className="text-muted mt-1 border-dotted border-b"
          style={{ fontSize: "0.8rem" }}
          to={`/auth/forgot${props.location.search}`}
        >
          {t("auth.login.forgot")}
        </Link> */}
      </form>
      <div className="mt-5">
        <Link
          className="border-dotted border-b"
          style={{ fontSize: "0.8rem" }}
          to={`/auth/register${props.location.search}`}
        >
          {t("auth.login.register")}
          <span className="mx-1" />
          <span className="inline-block">
            <LeftArrowIcon
              className={classnames(
                "self-center flex justify-center align-middle",
                {
                  [css({ transform: "rotateZ(180deg)" })]: i18n.dir() === "ltr",
                }
              )}
              fill="currentColor"
              fillRule="evenodd"
            />
          </span>
        </Link>
      </div>
    </div>
  );
}

function validate(values, props) {
  const errors = {};
  const { identifier, password } = values;

  if (!identifier) errors.identifier = props.t("glossary:validation.empty");
  else if (isNaN(identifier) && !emailRegExp.test(identifier)) {
    errors.identifier = props.t("glossary:validation.wrongEmail");
  } else if (!isNaN(identifier) && !mobileRegExp.test(identifier)) {
    errors.identifier = props.t("glossary:validation.wrongMobile");
  }

  if (!password) errors.password = props.t("glossary:validation.empty");

  return errors;
}

const mapStateToProps = ({ auth, geo }) => ({ auth, geo });

export default connect(mapStateToProps, { loginUser })(
  withTranslation()(reduxForm({ form: "login", validate })(Login))
);
